import styled from "styled-components";
import { Button } from "react-bootstrap";
import { colors } from "../../const/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  background: white;
  border-bottom: 1px solid lightgray;
  position: fixed;
  top: 0;
  z-index: 100;
`;

export const NavbarMobileWrapper = styled.div`
  z-index: 999;
  @media (max-width: 600px) {
    height: auto;
    background-color: #eff3fa;
    box-shadow: 0px 5px 10px gray;
    position: absolute;
    width: 50%;
    top: 50px;
  }
  @media (min-width: 600px) {
    display: none;
  }
`;

export const NavbarDesktopWrapper = styled.div`
  display: flex;
  padding-right: 60px;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const Icon = styled(Button)`
  margin-top: -12px;
  background: none;
  border: none;
  color: ${colors.white};
  font-size: 45px;
  outline: none;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    background: none;
    border: none;
    outline: none;
  }
  @media (min-width: 600px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  width: 400px;
  height: 65px;
  display: flex;
  align-items: center;
  margin: auto 2rem;
`;

export const Logo = styled.img`
  color: #2097f5;
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const HamburgerMenu = styled(Button)`
  background: none;
  border: none;
  margin: auto 1rem;
  color: ${colors.white};
  &:hover,
  &:active,
  &:focus {
    color: ${colors.white};
    outline: none;
  }
`;

export const HumburgerIcon = styled(FontAwesomeIcon)`
  color: black;
`;

export const Select = styled.select`
  font-family: "Montserrat", sans-serif;
  width: 100px;
  padding: 4%;
  outline: none;
  border-radius: 10px;
  box-shadow: 0px 0px 1px;
`;

export const IconWrapper = styled.span`
  padding-left: 2%;
  padding-top: 4px;
  cursor: pointer;
`;

export const ProfileImage = styled.img`
  height: 32px;
  width: 45px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  margin-top: 4%;
`;

export const ProfileContainer = styled.div`
  top: -10px;
  position: relative;
  margin-right: -50px;
  border-radius: 20px;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  margin-top: -4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  ${ProfileContainer}:hover & {
    display: block;
  }
`;

export const DropdownContentLink = styled.a`
  font-family: "Montserrat", sans-serif;
  color: black;
  padding: 4px 12px;
  padding-top: 4%;
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background-color: #2097f5;
    color: white;
  }
`;

export const UserName = styled.p`
  color: black;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-top: 8%;
  margin-left: 12px;
`;

export const ProfileFlexWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const FontIcon = styled(FontAwesomeIcon)`
  &:hover {
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const FontIconbell = styled(FontAwesomeIcon)`
  &:hover {
    animation: ring 4s 0.1s ease-in-out;
    transform-origin: 50% 4px;
  }

  @keyframes ring {
    0% {
      transform: rotate(0);
    }
    1% {
      transform: rotate(30deg);
    }
    3% {
      transform: rotate(-28deg);
    }
    5% {
      transform: rotate(34deg);
    }
    7% {
      transform: rotate(-32deg);
    }
    9% {
      transform: rotate(30deg);
    }
    11% {
      transform: rotate(-28deg);
    }
    13% {
      transform: rotate(26deg);
    }
    15% {
      transform: rotate(-24deg);
    }
    17% {
      transform: rotate(22deg);
    }
    19% {
      transform: rotate(-20deg);
    }
    21% {
      transform: rotate(18deg);
    }
    23% {
      transform: rotate(-16deg);
    }
    25% {
      transform: rotate(14deg);
    }
    27% {
      transform: rotate(-12deg);
    }
    29% {
      transform: rotate(10deg);
    }
    31% {
      transform: rotate(-8deg);
    }
    33% {
      transform: rotate(6deg);
    }
    35% {
      transform: rotate(-4deg);
    }
    37% {
      transform: rotate(2deg);
    }
    39% {
      transform: rotate(-1deg);
    }
    41% {
      transform: rotate(1deg);
    }

    43% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(0);
    }
  }
`;
