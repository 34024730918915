const english = {
  header: {
    main: {
      ourSolutions: "Our solutions",
      vacancies: "Vacancies",
      more: "More",
      aboutUs: "About us",
      contactUs: "Contact us",
      news: "News",
      faq: "FAQ",
      integrity: "Integrity & User Policy",
      apply: "Get started",
      signIn: "Log in",
      forgotPassword: "Forgotten password?",
      para1:
        "Provide your registered email address to receive a link to reset your password. Don't forget to check your spam folder if you do not see email from us.",
      emailPlaceholder: "Email address",
      submit: "Submit",
      para2:
        "Don't hesitate to contact us if you are experiencing problems with the link or resetting your password",
      checkYourEmail: "Check your email",
      para3:
        "You will receive an email with a link to reset your password shortly. If you have not received the email from DoHR, please check your spam folder or resubmit your email address.",
      backToLogin: "Back to Login",
      needHelp: "Need help?",
      contactSupport: "Contact support",
    },
    sideHeading: {
      SA_and_SM: "Substitute and Absence Management",
      Recruitment: "Recruitment",
      Personal_Development: "Onboarding and Personal Development",
    },
  },
  landing: {
    apply: "Apply now",
    learnmore: "Learn more",
    imagin: "Become DoHR (/ˈdɔr/) customer and experience....",
    sequence1: [
      "Managing your staff absences with the click of a button.",

      "Having a daily overview of your staff absences at your finger tips.",

      "Getting the right people without having to spend your days chasing substitutes and timesheets.",

      "Getting the right candidates for your vacancies stress free and hassle free.",
      "Bringing the best talent into your workplace cost effectively.",
      "Having insight to all the data for your statistics needs.",

      "Tracking professional development, collaboration and learning of your staff in one place.",
    ],
    sequence2: [
      "Looking for an easy and hassle free way to work extra?",
      "Are you interested in making your own decisions on when and where you would like to work extra?",
    ],
    findout:
      "Find out more about how DoHR Solutions can help you with your needs!",
    stopImg: " Well, stop imagining and take action!",
    getStarted: "Get Started",
    extrawork: "Are you looking for extra work?",
    ifsounds:
      "If this sounds like you, apply now to register your interest in becoming a DoHR substitute or learn more about how it works.",
    seeking: "Are you seeking new opportunities?",
    great: "Great, you came to the right place!",
    readyto:
      "We at DoHR are ready to help you match your career aspirations with our customer needs.",
    refer: `Refer to "Learn more" to find out how it works!`,
    open: "Open applications",
  },

  footer: {
    Our_solutions: "Our solutions",
    saam: "SAAM",
    Recruitment: "Recruitment",
    Personal_Development: "Onboarding and Personal Development",
    gswith: "Get started with DoHR",
    Customers: "Customers",
    Substitutes: "Substitutes",
    Jobseekers: "Job seekers",
    more: "More",
    Visiting_address: "Visiting address:",
    Bollgatan: "Bollgatan 3B, Växjö",

    contact: {
      Contact_Us: "Contact Us",
      Phone: "Phone:0470 - 78 50 00",
      Opening: "Opening hours weekdays: 09:00 - 16:00",
      closing: "Closed for lunch: 12:00 - 13:00",
    },
    other: {
      Download: "Download our app",
      follow_us: "Follow us",
      Privacy_statement: "Privacy statement",
      Cookie_Policy: "Cookie Policy",
      Integrity_and_security: "Integrity and security",
      terms: "Agreements & terms",
      rights: "Copyright 2024 DoHR",
    },
  },
  contactForm: {
    fill: "Fill in your details below to submit your query!",
    Contactusform: "Contact us form",
    CompanyName: "Company name",
    FirstName: "First name",
    LastName: "Last name",
    EnterName: "Enter name",
    EnterMailid: "Email",
    PhoneNumber: "Phone number",
    EnterPhoneNumber: "Phone number",
    Description: "Description",
    descriptionPlaceHolder: "Describe your query",
    submit: "Submit",
    messages: "Your query has now been submitted!",
  },
  dashboard: {
    tab: {
      header: {
        today: "Today",
        tommorow: "Tomorrow",
        custom: "Select different date",
      },
      content: {
        absence: "Total absences",
        filled: "Filled vacancies",
        unfilled: "Unfilled vacancies",
        covered: "Total covered",
        unCovered: "Total uncovered",
      },
      teacherContent: {
        myAssignedCover: "My Assigned Cover",
      },
    },
    balance: {
      hoursBalance: "Pre purchased hours overview",
      hoursPurchased: "Total hours",
      usedHours: "Hours used",
      remainingHours: "Hours remaining",
      topUp: "Manual top up",
    },
  },
  signUp: {
    our_Solutions_1: " Outsource your needs to DoHR!",
    our_Solutions_2: "Learn more and get started...",
    LearnMore: "Learn more",
    absent_management: "Substitute and Absence Management (SAAM)",
    recruitment: "Recruitment",
    personal_growth: "Personal Development",
    saam: {
      have: "Have peace of mind knowing you have the right people at the right place when using the DoHR mobile app or web-based application!",
      learnSaam: "Learn more about SAAM",
      getMobileApp: "Get started with DoHR mobile app",
      whether:
        "Whether you choose to outsource your staffing needs using the DoHR mobile app or web-based application (SAAM), you will...",
      cover: "Cover your staff absences with the click of a button",
      rightpeople:
        "Get the right people without having to spend your days chasing substitutes",
      avoid: "Avoid last minute unfilled absences",
      stop: "Stop manual handling of timesheets",
      daily:
        "Have a daily overview of your staff absences at your finger tips ",
      get: "Get different statistics of staff absences",
      pop: "Only available for web-based application",
      which: "Which of the solutions below suits your needs better?",
      doMobile: "DoHR Mobile App",
      doWeb: "DoHR Web-Based Application",
      table: {
        publish: "Publishing of vacancies",
        overjobs: "Overview and status of published jobs",
        time: "Timesheet approval",
        chat: "Chat",
        management: "Management of leave application",
        register: "Registration of work schedule",
        overview: "Overview of different absences",
        effective: "Effective resource management",
        discount: "Discounted hourly rate",
        statics: "Statistics",
      },
      popModal:
        "SAAM is a DoHR web-based application to help you manage staff absences. You have the choice of using the platform with your own resources or outsourcing your substitute needs to DoHR. To learn how to access the DoHR web-based application, contact us.",
    },
    getstartpage: {
      header_1: "Whether you are a customer or a job seeker,",
      header_2: "achieve your goal with DoHR!",
      subheading_1: "DoHR for Customers",
      subheading_2: "DoHR for Substitutes",
      subheading_3: "DoHR for Job seekers",
      button: "Get started",
    },
    getstarted_substitute: {
      header: "Stop searching, start working!",
      headerpara_1:
        " DoHR is always looking for people who want to work extra as a substitute to help",
      headerpara_2:
        " our customers manage short term absences of their staff with last minute notice.",
      headerpara_3:
        " To register your interest to become a DoHR substitute, download",
      headerpara_4: " the DoHR app free of charge or apply now.",
      applynow: "Apply now",
      subheading: "How does it work?",
      subheading_1: "Download the DoHR app",
      subheadingpara_1_1: "Get the DoHR app from",
      subheadingpara_1_2: "Google Play or the App Store",
      subheading_2: "Complete the substitute application form",
      subheadingpara_2_1: "To apply, complete online form on DoHR website",
      subheadingpara_2_2: "or use the link ",
      subheadingpara_2_3: "on DoHR mobile app",
      subheading_3: "Recruitment and training",
      subheadingpara_3_1: "Go through the interview process,",
      subheadingpara_3_2: "and if successful, complete",
      subheadingpara_3_3: "DoHR training for substitutes",
      subheading_4: "Log in",
      subheadingpara_4_1: "Log in using the user name",
      subheadingpara_4_2: "and password you received",
      subheadingpara_4_3: "from the DoHR Support team",
      subheading_5: "Accept assignment",
      subheadingpara_5_1:
        "Accept and complete assignments based on your preferences and availability",
      subheading_6: "Get paid",
      subheadingpara_6_1: "Report your worked hours ",
      subheadingpara_6_2: "and get paid ",
      lastline_1: "Visit",
      lastline_2: "FAQ",
      lastline_3: "for more information",
    },
    getstarted_jobseekers: {
      JobSeekerHeader1: "Let's embark on your career",
      JobSeekerHeader2: "journey together!",
      JobSeekerPara:
        "We are always looking for new talent and we are ready to help you match your career aspirations with our customer needs. ",
      JobseekerPara1_1:
        "Let's discover together how we can help you find the right job and reach your career destination!",
      JobSeekerPara1:
        "Check out our current vacancies or complete the application form to register your interest",
      JobSeekerPara2: "so we can find you the role that fits!",
      Curentopenings: "Current openings",
      Openapplications: "Open applications",
      work: "How does it work?",
      govacancy: "Go to Vacancies",
      browse: " Browse through all available vacancies and apply",
      application: "Application form",
      fill: " If you did not find  ",
      fill1: "any suitable vacancy - fill in an",
      fill2: "application form",
      next: "What's next?",
      review: "  We will review your application",
      review1: "and contact you within 2-3 weeks.",
      review2: "",
      openapplication: "Open applications",
    },

    customer: {
      stop: "Stop chasing substitutes!",
      straight: "Get started straight away!",
      download:
        "Download the DoHR mobile app free of charge and experience on-time arrival of our substitute, ready to fulfill the assignment.",
      there: "There's even more...",
      how: "How does the DoHR mobile app work?",
      app: "Download the DoHR app",
      complete: "Complete registration",
      log: "Log in",
      publish: "Publish vacancy",
      approve: "Approve timesheet",
    },
    recruitments: {
      make: "DoHR makes recruitment affordable!",
      strategy: "Why is the DoHR recruitment strategy cost-effective?",
      heading1: "Traditionally, the word",
      heading2: "recruitment",
      heading3: "is associated with",
      heading4: "high costs.",
      heading5: "Many companies cannot justify",
      heading6: "the cost versus benefits of using",
      heading7: "recruitment company",
      heading8: "to find resources.",
      heading9:
        " As a result, finding the right candidate can be a time-consuming process ",
      heading10: "for the company.",
      heading11: " When you outsource your staffing needs to",
      heading12: "DoHR,",
      heading13: "we help you find the right candidates for a",
      heading14: "flat fee",

      tofind: "To find out more, contact us!",
      tooltip:
        "A flat fee per position applies to all positions in all sectors. For the educational sector, we include an unlimited number of teaching positions per school throughout the year for a flat fee. For further information, contact us.",
      means: "Recruitment with DoHR means...",
      stress: "Stress-free hiring",
      outsource: "Outsourcing of advertising, candidate screening and vetting",
      turn: "Turn-key recruitment services",
      best: "Having the best talent brought to you",
      cost: "Affordable one time cost",
    },
    Onboarding_development: {
      c: "c",
      o: "o",
      m: "m",
      i: "i",
      n: "n",
      g: "g",
      s: "s",
      o1: "o",
      o2: "o",
      n2: "n",
    },
    vacancies: {
      open: "Open DoHR to new opportunities!",
      talent: "We at DoHR are always looking for new talent!",
      see: "See our current openings below or complete an application form so we can find a role that fits!",
      openapplication: "Open applications",
      current: "Current openings",
      apply: "Apply",
      location: "Location",
      sector: "Sector",
      search: "Search",
      close: "Close",
      readmore: "Read more",
    },
    about: {
      C: "Our story is simple!",
    },
    news: {
      what: "What is happening at DoHR?",
      newsinsight: "News and Insights",
      filter: "Filter by category or month",
      cat: "Category",
      emailmessage: "Thank you for subscribing!",
      close: "Close",
      categorylist: [
        {
          id: "01",
          name: "For customers",
        },
        {
          id: "02",
          name: "For substitutes",
        },
        {
          id: "03",
          name: "For other job seekers",
        },
        {
          id: "04",
          name: "All",
        },
      ],
      forcus: "For Customers",
      forsub: "For substitutes",
      forother: "For other job seekers",
      all: "All",
      search: "Search",
      share: "Share",
      learn: "Learn",
      more: "more",
      stay: "Stay updated with our news!",
      email: "Email",
      submit: "Submit",
    },
    faq: {
      title: "Frequently Asked Questions",
      substitutes: "Substitutes",
      subques1: "What does it mean to work as a substitute?",
      subans1:
        "As a substitute, you will be temporarily replacing a regular staff member during their absence. You will work on an hourly basis and have the flexibility to work extra when it suits you.",
      subques2: "What is a police report, and why do I need to have one?",
      subans2:
        "Swedish law stipulates that those who work within schools and preschools are required to present a valid police clearance check to the employer. For more information and to order a copy",
      subques3: "How do I become a part of the DoHR Substitute team?",
      subans3_1: "Complete the",
      subans3_2: "Substitute Application",
      subans3_3:
        ' or apply via our home page under "Are you looking for extra work?" The candidates who are successful will be contacted to proceed further with the recruitment process.',
      subques_4: "As a DoHR substitute, where am I most likely to work?",
      subans_4:
        "The needs of customers determine the occupational sector where you will likely work. Currently our customers come from a variety of sectors, including education, social care, office and administration, etc.",
      subques5: "What is a typical duration of a job for a DoHR substitute?",
      subans5:
        "Customer needs determine how long a typical job lasts, which can range from a few hours to several days.",
      subques6: "How am I going to get work when I am a DoHR substitute?",
      subans6:
        "As a DoHR substitute, you will have access to our DoHR mobile app. You will be able to view all available jobs and accept a job that suits you at your convenience.",
      subques7: "Can I send my CV to you?",
      subans7_1: "We accept only online",
      subans7_2: "Substitute Application Forms",
      subans7_3: " submitted via our website.",

      customer: "Customer",
      cusques1: "  What is DoHR?",
      cusans1:
        "We, at DoHR (/ˈdɔr/) - Dimensions of HR, offer affordable solutions for your staffing needs (whether your need is permanent, short term or last minute). With our DoHR mobile app and web-based applications, discover how easy it is to manage effectively different Dimensions of HR.",
      cusques2: "Why should we outsource our recruitment needs to DoHR?",
      cusans2:
        " Avoid unnecessary fees when you outsource your recruitment needs to us. At DoHR, we will find the right candidate for a flat fee and help you stay within your budget.",
      cusques3: "What do I do if I urgently need a substitute?",
      cusans3:
        " Simply download our free mobile app to have access to our available substitutes right away when you urgently need a substitute during a staff absence.",
      cusques4: "How do I get access to DoHR web-based application??",
      cusans4_1:
        "  It is great that you are interested in using our web-based application.",
      cusans4_2: "Contact us",
      cusans4_3: "so we can get you started!",
      jobseekers: "Job seekers",
      jobques1: "  Who are DoHR customers?",
      jobans1:
        "Customers of DoHR come from a variety of industries, including those related to education, social care, IT, office work, and administration, among others.",
      jobques2: "What is an open application?",
      jobans2:
        " We recognize the value of professional networking, which is why we invite you to fill out our open application form. If you do not find a role that matches among our current openings, we can assist you in finding one.",
      jobques3: "Can I send my CV to you?",
      jobans3_1: " We accept only",
      jobans3_2: " applications",
      jobans3_3: "submitted via our website.",
      jobques4:
        "Can I still submit my application if I live outside of Sweden?",
      jobans4:
        "We collaborate with customers who welcome international professionals so we encourage you to go through our current openings or submit an open application.",
    },
  },
  Tables: {
    teacher: {
      mySchedule: {
        sectionTitle: "My Schedule",
        tableHeader: [
          "Day",
          "Start Time",
          "End Time",
          "Subject",
          "Year Group",
          "Location/Room",
          "Action",
        ],
      },
    },
    absentStaff: {
      sectionTitle: "Absent staff overview",
      tableHeader: [
        "No",
        // "Name of organisation",
        "First Name",
        "Last Name",
        "Date From",
        "Date To",
        "Duration",
        "Type Of Leave",
        "Substitute required",
      ],
    },
    management: {
      sectionTitle: "User list",
      tableHeader: [
        "No",
        "First name",
        "Last name",
        "Email address",
        "Permission",
        "Status",
        "Action",
      ],
      button: "Create new user",
      modal:
        "Please confirm if you want to delete this user. Selecting 'Yes' will affect your statistics. We suggest deactivating the user instead if you wish to retain access to statistical data",
    },
    uncovered: {
      sectionTitle: "Uncovered",
      tableHeader: [
        "Date",
        "Absent staff name",
        "Year Group",
        "Subject",
        "Room/Location",
        "Start Time",
        "End Time",
        "Lesson Plan Instruction",
        "Select",
        "Action",
      ],
      button: "Create Vacancy",
    },
    cover: {
      sectionTitle: "Covered",
      tableHeader: [
        "Date",
        "Start Time",
        "End Time",
        "Year Group",
        "Room/Location",
        "Absent staff name",
        "Substitute name",
        "Covered",
      ],
    },
    draft: {
      sectionTitle: "My draft",
      tableHeader: [
        "SNo",
        "Position",
        "Day",
        "Vacancy Date",
        "Start Time",
        "End Time",
        "Action",
      ],
    },
    published: {
      sectionTitle: "Published Vacancies",
      tableHeader: [
        "No",
        "Position",
        "Vacancy date",
        "Start Time",
        "End Time",
        "Published date-time",
        "Published",
        "Status",
        "Detail view",

        // "Name of the Organisation",
        "Action",
      ],
    },
    completed: {
      sectionTitle: "Completed vacancies",
      tableHeader: [
        "No",
        "Completed by",
        "Vacancy date",
        "Start Time",
        "End Time",
        "Position",
        "Original",
        "Reported",
        "Approved ",
        "Status",
        "Detail view",
        "Action",
      ],
      dropList: [
        {
          id: "01",
          name: "Internally",
        },
        {
          id: "02",
          name: "Externally",
        },
      ],
      placeholder: {
        dropdown: "Select a DropDown",
      },
    },
    approved: {
      sectionTitle: "Approved Leave Request",
      tableHeader: [
        "SNo",
        "Name",
        "Leave Type",
        "Substitute Required",
        "From Date",
        "To Date",
        "Status",
        "Duration",
        "Submitted on",
        "Approved on",
        "Approved by",
      ],
      button: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    pending: {
      sectionTitle: "Pending Leave Request",
      tableHeader: [
        "SNo",
        "Name",
        "Leave Type",
        "Substitute Required",
        "From Date",
        "To Date",
        "From Time",
        "To Time",
      ],
      tableHeaderSh: [
        "SNo",
        "Name",
        "Leave Type",
        "Substitute Required",
        "From Date",
        "To Date",
        "From Time",
        "To Time",
        "Action",
      ],
      button: {
        approve: "Approve",
        deny: "Deny",
      },
    },
    myconsultant: {
      sectionTitle: "My Consultants",
      tableHeader: ["S.No", "Name", "Action"],
    },
    statistic: {
      sectionTitle: " Statistics",
      selectDate: "Select Date",
    },
    chats: {
      sectionTitle: "Chat",
      tableHeader: [
        "S.No",
        "Name",
        "Position",
        "Vacancy Date",
        "Start Time",
        "End Time",
        "Action",
      ],
      chatMessage: {
        sectionTitle: "Message",
      },
    },
  },
  register: {
    sectionTitle: "Substitute Application",
    sectionprocedure:
      "Fill out the form to express your interest in becoming part of the DoHR Substitute Team. We will contact you after we review your application.",
    personal: "My personal details",
    work: "My work experience",
    availablity: "My availability",
    lang: "My language skills",
    other: "Other information",
    firstName: "First name",
    lastName: "Last name",
    address: "Address",
    email: "Email",
    dob: "Date of birth",
    city: "City",
    area_name: "Area name",
    Post_code: "Post code",
    phoneNumber: "Contact number",
    swedishNumber: "Swedish Personal number ",
    studyTime: "State hours/week",
    selfEmploye: "I am self-employed/freelancer",
    errormsgcheck: "To submit the application, all checkboxes must be ticked",
    freelancer: "I am retired/pensioner",
    primary_occupation: "I have another primary occupation (minimum 50%)",
    swedishBankAccount: "I have a swedish bank account",
    student: "I am a student",
    education_type: "Type of study",
    Otherlanguage: "Other language",
    Time: [
      {
        id: "01",
        name: "Full Time",
      },
      {
        id: "02",
        name: "Part Time",
      },
    ],
    Uploadproof: "Upload proof of your studies ",
    Uploadproofi:
      "The proof of study can be either a letter of admission, a CSN card, or something similar",
    prefferedWorkInsti: "My preferred type of workplace",
    preferedWorkLoc: "My preferred work location",
    preferedWorkLoclabel: "Write name of the city (for example, Stockholm)",
    doing: "Tell us what you are doing currently",
    workLocation: "Work location",
    description: "Briefly describe why you would like to work as a substitute",
    workExperienceinSchool:
      "I have experience working with children/young adults in a school setting",
    workExperienceoutSchool:
      "I have experience working with children/young adults outside of school",
    otherOccupation: "I have other work experience",
    workStatrt: "When can you start?",
    Assoonaspossible: "As soon as possible",
    Specifydate: "Specify date",
    Icanstart: "I can start",
    date: "Specify Date",
    swedish: "Swedish",
    english: "English",
    otherLange: "Other language",
    policeCLearenceReport: "I have a valid police clearance certificate",
    policecert: " What is a police clearance certificate?",
    drivingLicence: "I have a valid driver's licence",
    nameOfEmploye: "Employer",
    durations: "Duration",
    titleofRole: "Role",
    keyRes: "Responsibilities",
    companyOfName: "Enter Company Name",
    nameofIns: "Institution name",
    selectDp: "Select",
    upfile: "Upload file",
    howMayHours: "Select hours/week",
    workWeekend: "I can work evenings/weekends",
    enterName: "Enter Name",
    declaration_1:
      " DoHR is responsible for registering the personal information you provide in your application. For more information, please see our",
    declaration_2: " Terms of use and Privacy Policy.",
    declaration_3:
      " By submitting my personal information and application to DoHR, I acknowledge that:",
    checkbox_1:
      " I have read, understand and agree to the above Terms of use and Privacy Policy",
    checkbox_2:
      "the information I provided is complete and truthful, and that I understand that inaccurate information may result in the rejection or termination of my application",
    checkbox_3:
      "I agree that DoHR may save and process the information submitted and subsequently collected, share information about new assignments, and use information for job-related marketing purposes",
    submits: "Save",
    cancel: "Cancel",
    onsubmit: "Thank you for submitting your application to DoHR!",
    message: "Your application was successfully submitted!",
    mailidexist:
      "An account with this email address already exists. Try to log in or select forgotten password",
    YESORNO: [
      {
        id: "01",
        name: "Yes",
      },
      {
        id: "02",
        name: "No",
      },
    ],
    HOURSELECTION: [
      {
        id: "01",
        name: "30+ hours per week ",
      },
      {
        id: "02",
        name: "  15 - 30 hours per week ",
      },
      {
        id: "03",
        name: " 5 - 15 hours per week ",
      },
      {
        id: "04",
        name: "Flexible, open to any of the above alternatives ",
      },
    ],
    Preschool: "Preschool",
    school: "School",
    Gymnasium: "Gymnasium",
    CareInstitution: "Care Institution",
    OtherSpecify: "If other, specify",
    Specify: "Specify",
    DOB: "YYYY/MM/DD",
    LANG: "eng",
    DATE: "YY/MM",
    Beginner: "Beginner",
    Intermediate: "Intermediate",
    Proficient: "Proficient",
    Fluent: "Fluent",
    Native: "Native",
    submit: "Submit",
    mail: "There is already an application with this email address. Try using another email address or if you have questions, please email support@dohr.io ",
    workSweden: "I have a right to work in Sweden",
    swedishlaw1:
      "Swedish law requires that persons working in schools and preschools must present a valid police clearance certificate to the employer. For more information and to order a copy, visit > > > `",
    swedishlaw2:
      "After you receive your certificate, please remember to email us a copy.This is because you cannot start working without a valid certificate.",
    permitlaw: " To work in Sweden, you need a valid work permit.",
    swedishlaw11:
      "Swedish law requires that persons working in schools and preschools must present a valid police clearance certificate to the employer. For more information and to order a copy, visit > > > https://polisen.se/tjanster-tillstand/belastningsregistret/skola-eller-forskola/ `",
    permitlaw2: "For more information, visit ",
    Passport: "Upload the main page of your passport",
    PermitCard: "Upload a copy of your work permit card",
    hear: " How did you hear about us?",
    EUcitizen: "I am an EU citizen",
    nonEUcitizen: "I am a non-EU citizen",
    howKnowAboutUs: [
      {
        id: "01",
        name: "Arbetsförmedlingen",
      },
      {
        id: "02",
        name: "Marketing event",
      },
      {
        id: "03",
        name: "Word of mouth",
      },
      {
        id: "04",
        name: "Job posting",
      },
      {
        id: "05",
        name: "Instagram",
      },
      {
        id: "06",
        name: "Facebook",
      },
      {
        id: "07",
        name: "Linkedin",
      },
      {
        id: "08",
        name: "Google",
      },
      {
        id: "09",
        name: "Tiktok",
      },
    ],
  },
  applicationForm: {
    title: "Open application form",
    Jobapplication: "Job application",
    titledetails:
      "Thank you for taking your time to fill in our open application form. After you have completed your application a confirmation email will be sent to you.",
    titledetailsvacancy:
      "Thank you for taking your time to fill in the job application. After you have completed your application a confirmation email will be sent to you.",
    personaldetails: "Personal details",
    firstName: "First name",
    lastName: "Last name",
    address: "Address",
    email: "Email",
    dob: "Date of birth",
    DATES: "yy/mm",
    city: "City",
    area_name: "Area name",
    Post_code: "Post code",
    phoneNumber: "Contact number",
    select: "Select",
    telusbriefly: " Tell us briefly why you are interested in this position",
    worklocation: "My preferred work location",
    statelocation: "State location",
    namecity: "Write name of the city (for example, Stockholm)",
    swedishNumber: "Swedish Personal number ",
    swedishBankAccount: "I have a swedish bank account",
    workexperience: "Relevant work experience",
    Employmenthistory: "Employment history",
    Employer: "Employer",
    From: "From",
    to: "to",
    Relevanteducation: "Relevant education",
    Institutionname: "Institution name",
    Education: "Education",
    occupitationalSector: "Select occupational field",
    stateyourOccupation: "Select position",
    jobTitle: "Job title",
    preferredWorkIns: "State your preferred work institution",
    preSchool: "Preschool",
    school: "School",
    DOB: "YYYY/MM/DD",
    highSchool: "High School",
    preferredLocation: "State your preferred location",
    specifyNameCity: "Specify Name of the City",
    areQualifiedTeacher: "Are you a qualified teacher?",
    qualifiedSubject: "Qualified to teach following subject/subjects",
    qualifiedAgeGroups: "Qualified to teach following age groups",
    swedishlärarlegitimation: "Do you have a Swedish lärarlegitimation",
    uploadLärarlegitimation: " Upload lärarlegitimation here",
    TeachingExperience: "What subjects do you have teaching experience in?",
    ageGroupsExperience: "What age groups do you have experience with?",
    studyingteacher: "Are you currently studying to become a teacher? ",
    currentlyQWualifiedTeacher: "I will be a qualified teacher by",
    willQualifiedSubjects: "I will be qualified to teach following subjects",
    willQualifiedAgeGroups: "I will be qualified to teach following age groups",
    langSkills: " My language skills",
    nameOflanf: "Name of the Language",
    workSweden: "I have a right to work in Sweden",
    howDoHR: "How did you find out about DoHR? ",
    areEveningsweekends: "Are you able to work evenings / weekends?",
    education: "Education",
    completedIn: "Completed in",
    EUcitizen: "I am an EU citizen",
    nonEUcitizen: "I am a non-EU citizen",
    Passport: "Upload the main page of your passport",
    PermitCard: "Upload a copy of your work permit card",
    linkedin: "Provide a link to your LinkedIn",
    hear: " How did you hear about us?",
    nonqualified: "I am a non-qualified teacher with teaching experience ",
    submit: "Submit",
    toteacher: "I am currently studying to become a teacher",
    Occuppation: "Specify occupational field",
    howKnowAboutUs: [
      {
        id: "01",
        name: "Arbetsförmedlingen",
      },
      {
        id: "02",
        name: "Marketing event",
      },
      {
        id: "03",
        name: "Word of mouth",
      },
      {
        id: "04",
        name: "Job posting",
      },
      {
        id: "05",
        name: "Instagram",
      },
      {
        id: "06",
        name: "Facebook",
      },
      {
        id: "07",
        name: "Linkedin",
      },
      {
        id: "08",
        name: "Google",
      },
      {
        id: "09",
        name: "Tiktok",
      },
    ],
    avalibalityList: [
      {
        id: "01",
        name: "Anytime ",
      },
      {
        id: "02",
        name: "Specify date",
      },
    ],
    OccupitationalList: [
      {
        id: "01",
        name: "Education",
      },

      {
        id: "02",
        name: "Social Care",
      },
      {
        id: "03",
        name: "Office and administration",
      },
      {
        id: "04",
        name: "IT",
      },
      {
        id: "05",
        name: "Other",
      },
    ],
    position: [
      {
        id: "01",
        name: "Teaching positions",
      },
      {
        id: "02",
        name: "Non-teaching positions",
      },
    ],
    teaching: [
      {
        id: "01",
        name: "Preschool teacher",
      },
      {
        id: "02",
        name: "School teacher",
      },
      {
        id: "03",
        name: "Gymnasium teacher",
      },
      {
        id: "04",
        name: "Special education teacher",
      },
    ],

    non_teaching: [
      {
        id: "01",
        name: "Career counsellor",
      },
      {
        id: "02",
        name: "Fritidsledare",
      },
      {
        id: "03",
        name: "School nurse",
      },
      {
        id: "04",
        name: "School counsellor",
      },
      {
        id: "5",
        name: "Librarian",
      },
      {
        id: "6",
        name: "Administration",
      },
      {
        id: "7",
        name: "Student care",
      },
      {
        id: "8",
        name: "Leadership",
      },
      {
        id: "9",
        name: "IT",
      },
      {
        id: "10",
        name: "Other",
      },
    ],
    subjectList: [
      {
        id: "01",
        name: "English",
      },
      {
        id: "02",
        name: "Mathematics",
      },
      {
        id: "03",
        name: "Science",
      },
      {
        id: "04",
        name: "Social Studies",
      },
      {
        id: "05",
        name: "Swedish",
      },
      {
        id: "06",
        name: "PE",
      },
      {
        id: "07",
        name: "Modern Languages",
      },
      {
        id: "08",
        name: "Art",
      },
      {
        id: "09",
        name: "Home Ec",
      },
      {
        id: "10",
        name: "Textiles",
      },
      {
        id: "11",
        name: "Woodwork",
      },
      {
        id: "12",
        name: "Music",
      },
      {
        id: "13",
        name: "Life skills",
      },
      {
        id: "14",
        name: " Computer skills",
      },
      {
        id: "15",
        name: "Class teacher",
      },
    ],
    ageGroupList: [
      {
        id: "01",
        name: "Preschool",
      },
      {
        id: "02",
        name: "Grade F-3",
      },
      {
        id: "03",
        name: "Grade 4-6",
      },
      {
        id: "04",
        name: "Grade 7-9",
      },
      {
        id: "05",
        name: "Gymnasium",
      },
    ],
  },
};

export default english;
