import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
  ToastMessage,
} from "../../../../../components";
import { TYPE } from "../../../../../const/getType";

import {
  ApproveLeaveApi,
  DenyLeaveApi,
  GetAllLeaveApi,
} from "../../../../../redux/Hq_Management/LeaveManagement/api";
import { RootState } from "../../../../../redux/store";
import { CardWraper } from "../../Vacancies/PublishedVacancies/subcomponents";
import { IsPendingAction } from "./subcomponents";

const HQ_PendingLeave = (): ReactElement => {
  const dispatch = useDispatch();
  const { getAllLeaveReq, userInfo, isLoading, lang, message } = useSelector(
    (state: RootState) => ({
      getAllLeaveReq: state.LeaveMaster.getAllLeaveReq,
      isLoading: state.LeaveMaster.isLoading,
      userInfo: state.login.userInfo,
      lang: state.Language.language.Tables.pending,
      message: state.LeaveMaster.message,
    })
  );

  const [reason, setReason] = useState("");
  const [otherDetails, setOtherDetails] = useState({ id: "", email: "" });
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (userInfo?.type === TYPE.CUSTOMER) {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.ins_id,
          status: "0",
        })
      );
    } else {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.id,
          status: "0",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { sectionTitle, tableHeader, tableHeaderSh } = lang;

  return (
    <PageWrapper>
      <CardWraper width="90%">
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
        </FlexWrapper>
        <FlexWrapper justifyContent="center">
          {isLoading ? (
            <Loader />
          ) : (
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                {userInfo?.Role.toLowerCase() === "schoolmanagement" ? (
                  <tr style={{ textAlign: "center" }}>
                    {tableHeaderSh.map((data, index) => (
                      <th key={`Header Unique id -${index}`}>{data}</th>
                    ))}
                  </tr>
                ) : (
                  <tr>
                    {tableHeader.map((data, index) => (
                      <th key={`Header Unique id -${index}`}>{data}</th>
                    ))}
                  </tr>
                )}
              </thead>
              <tbody>
                {getAllLeaveReq.map((item, index) => (
                  <tr key={index} style={{ textAlign: "center" }}>
                    {item?.is_approved === 0 ? (
                      <>
                        <td>{++index}</td>
                        <td>{`${item.first_name}${item.last_name}`}</td>
                        <td>{item.leave_type}</td>
                        <td>{item.substitute_required === 0 ? "No" : "Yes"}</td>
                        <td>{moment(item.from_date).format("DD-MM-YYYY")}</td>
                        <td>{moment(item.to_date).format("DD-MM-YYYY")}</td>
                        <td>
                          {item.from_time === "00:00:00" ? "-" : item.from_time}
                        </td>
                        <td>
                          {item.to_time === "00:00:00" ? "-" : item.to_time}
                        </td>
                        {userInfo?.Role.toLowerCase() ===
                          "schoolmanagement" && (
                          <td style={{ width: "150px" }}>
                            <IsPendingAction
                              handleApprove={() => {
                                dispatch(
                                  ApproveLeaveApi({
                                    id: item.id,
                                    approved_by: `${userInfo?.data?.first_name}${userInfo?.data?.last_name}`,
                                    approved_by_id: userInfo.data.id,
                                    approved_or_denied_on: moment(
                                      new Date()
                                    ).format("DD-MM-YYYY"),
                                  })
                                );
                              }}
                              handleDeny={() => {
                                setOtherDetails({
                                  id: item.id,
                                  email: item.email_id,
                                });
                                setShow(true);
                              }}
                            />
                          </td>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </FlexWrapper>
      </CardWraper>

      <Modals
        center={true}
        handleCancel={() => {
          setShow(false);
        }}
        handleSubmit={() => {
          dispatch(
            DenyLeaveApi({
              id: otherDetails.id,
              email_id: otherDetails.email,
              reason: reason,
            })
          );
          setShow(false);
          setShowToast(true);
        }}
        show={show}
        sizeModal="lg"
        title="Reason For Denying"
      >
        <FlexWrapper justifyContent="center">
          <DropdownWrapper width="90">
            <Input
              inputType="textarea"
              value={reason}
              onChange={(value: string) => {
                setReason(value);
              }}
              placeholder="Enter Your Reason"
            />
          </DropdownWrapper>
        </FlexWrapper>
      </Modals>
      <ToastMessage
        show={showToast}
        message={message}
        bg={message === "Absence Denied" ? "green" : "red"}
        onCloseHandler={() => {
          setShowToast(false);
        }}
        top="25%"
        right="40%"
      />
    </PageWrapper>
  );
};

export default HQ_PendingLeave;
