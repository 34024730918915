import { validateRequired } from "../../../../../../helpers/formValidation";
import { ValidateScheduleForm } from "./typings";

const ScheduleValidation = ({
  AddSchedule,
  errors,
  field,
}: ValidateScheduleForm): Record<string, string> => {
  let formErrors: Record<string, string> = { ...errors };
  const { start_time, end_time, subject, day, year_group, classroom } =
    AddSchedule;
  switch (field) {
    case "start_time":
      formErrors["start_time"] = validateRequired(start_time);
      break;
    case "end_time":
      formErrors["end_time"] = validateRequired(end_time);
      break;
    case "subject":
      formErrors["subject"] = validateRequired(subject);
      break;
    case "year_group":
      formErrors["year_group"] = validateRequired(year_group);
      break;
    case "classroom":
      formErrors["classroom"] = validateRequired(classroom);
      break;
    default:
      formErrors["start_time"] = validateRequired(start_time);
      formErrors["end_time"] = validateRequired(end_time);
      formErrors["day"] = validateRequired(day);
      formErrors["subject"] = validateRequired(subject);
      formErrors["year_group"] = validateRequired(year_group);
      formErrors["classroom"] = validateRequired(classroom);
  }
  return formErrors;
};

export default ScheduleValidation;
