import ROUTES from "./routes";

const Teacher = [
  {
    title: "Dashboard",
    to: ROUTES.TEACHER.TEC_DASHBOARD,
  },
  {
    title: "Absence Management",
    childsItem: [
      {
        title: "Cover management",
        childsItem: [
          {
            title: "My schedule",
            to: ROUTES.TEACHER.TEC_MY_SCHEDULE,
          },
          {
            title: "My covered lessons",
            to: ROUTES.TEACHER.TEC_MY_COVERED_LESSON,
          },
          {
            title: "I covered lessons",
            to: ROUTES.TEACHER.TEC_ICOVERED_LESSON,
          },
          {
            title: "My assigned cover",
            to: ROUTES.TEACHER.TEC_MY_ASSIGNED_COVER,
          },
        ],
      },
      {
        title: "Register absence",
        to: ROUTES.TEACHER.TEC_REGISTER_LEAVE,
      },
      {
        title: "My Absences",
        to: ROUTES.TEACHER.TEC_MY_ABSENCE,
      },
    ],
  },
  {
    title: "More",
    childsItem: [
      {
        title: "FAQ",
        to: ROUTES.FAQ,
      },
      {
        title: "About DoHR",
        to: "",
      },
    ],
  },
];

const Hq_Management = [
  {
    title: "Dashboard",
    to: ROUTES.DASHBOARD,
  },
  {
    title: "Absence Management",
    childsItem: [
      {
        title: "Absent staff",
        to: ROUTES.HQ_MANAGEMENT.HQ_ABCENT_STAFF,
      },
      {
        title: "Cover management",
        childsItem: [
          {
            title: "Ad hoc vacancy",
            to: ROUTES.UNCOVERED_VACANCY_W_LESSON,
          },
          {
            title: "Uncovered",
            to: ROUTES.HQ_MANAGEMENT.HQ_UNCOVERED_TABEL,
          },
          {
            title: "Covered",
            to: ROUTES.HQ_MANAGEMENT.HQ_COVERED,
          },
          {
            title: "My draft",
            to: ROUTES.HQ_MANAGEMENT.HQ_MY_DRAFT,
          },
        ],
      },
      {
        title: "Vacancies",
        childsItem: [
          {
            title: "Published",
            to: ROUTES.HQ_MANAGEMENT.HQ_PUBLISHED_VACANCIES,
          },
          {
            title: "Completed",
            to: ROUTES.HQ_MANAGEMENT.HQ_COMPLETED_VACANCIES,
          },
        ],
      },
      {
        title: "Leave requests",
        childsItem: [
          {
            title: "Approved Request",
            to: ROUTES.HQ_MANAGEMENT.HQ_APPROVED_LEAVE_REQUEST,
          },
          {
            title: "Pending Request",
            to: ROUTES.HQ_MANAGEMENT.HQ_PENDING_LEAVE,
          },
        ],
      },
      // {
      //   title: "Register leave",
      //   to: "",
      // },
    ],
  },
  {
    title: "Statistics",
    to: ROUTES.INSTITUTE.STATICSTIS,
  },
  {
    title: "Chat",
    to: ROUTES.HQ_MANAGEMENT.CHAT,
  },
  {
    title: "More",
    childsItem: [
      {
        title: "My consultants",
        to: ROUTES.INSTITUTE.MY_CONSULTANTS,
      },
      {
        title: "FAQ",
        to: ROUTES.FAQ,
      },
      {
        title: "About DoHR",
        to: "",
      },
      {
        title: "Contact DoHR",
        to: "",
      },
    ],
  },
];

const Institute = [
  {
    title: "Dashboard",
    to: ROUTES.DASHBOARD,
  },
  {
    title: "Manage users",
    to: ROUTES.INSTITUTE.CREATED_CUSTOMER,
  },
  {
    title: "Absence Management",
    childsItem: [
      { title: "Absence staff", to: ROUTES.HQ_MANAGEMENT.HQ_ABCENT_STAFF },
      {
        title: "Cover management",
        childsItem: [
          {
            title: "Uncovered",
            to: ROUTES.HQ_MANAGEMENT.HQ_UNCOVERED_TABEL,
          },
          {
            title: "Covered",
            to: ROUTES.HQ_MANAGEMENT.HQ_COVERED,
          },
          {
            title: "My draft",
            to: ROUTES.HQ_MANAGEMENT.HQ_MY_DRAFT,
          },
        ],
      },
      {
        title: "Vacancies",
        childsItem: [
          {
            title: "Published",
            to: ROUTES.HQ_MANAGEMENT.HQ_PUBLISHED_VACANCIES,
          },
          {
            title: "Completed",
            to: ROUTES.HQ_MANAGEMENT.HQ_COMPLETED_VACANCIES,
          },
        ],
      },
      {
        title: "Leave requests",
        childsItem: [
          {
            title: "Approved Request",
            to: ROUTES.HQ_MANAGEMENT.HQ_APPROVED_LEAVE_REQUEST,
          },
          {
            title: "Pending Request",
            to: ROUTES.HQ_MANAGEMENT.HQ_PENDING_LEAVE,
          },
        ],
      },
      // {
      //   title: "Register leave",
      //   to: "",
      // },
    ],
  },
  {
    title: "Statistics",
    to: ROUTES.INSTITUTE.STATICSTIS,
  },
  {
    title: "More",
    childsItem: [
      {
        title: "My consultants",
        to: ROUTES.INSTITUTE.MY_CONSULTANTS,
      },
      {
        title: "FAQ",
        to: ROUTES.FAQ,
      },
      {
        title: "About DoHR",
        to: "",
      },
      {
        title: "Contact DoHR",
        to: "",
      },
    ],
  },
];

const Scl_Management = [
  {
    title: "Dashboard",
    to: ROUTES.DASHBOARD,
  },
  {
    title: "Absence Management",
    childsItem: [
      {
        title: "Absent staff",
        to: ROUTES.HQ_MANAGEMENT.HQ_ABCENT_STAFF,
      },
      {
        title: "Cover management",
        childsItem: [
          {
            title: "Ad hoc vacancy",
            to: ROUTES.UNCOVERED_VACANCY_W_LESSON,
          },
          {
            title: "Uncovered",
            to: ROUTES.HQ_MANAGEMENT.HQ_UNCOVERED_TABEL,
          },
          {
            title: "Covered",
            to: ROUTES.HQ_MANAGEMENT.HQ_COVERED,
          },
          {
            title: "My draft",
            to: ROUTES.HQ_MANAGEMENT.HQ_MY_DRAFT,
          },
        ],
      },
      {
        title: "Vacancies",
        childsItem: [
          {
            title: "Published",
            to: ROUTES.HQ_MANAGEMENT.HQ_PUBLISHED_VACANCIES,
          },
          {
            title: "Completed",
            to: ROUTES.HQ_MANAGEMENT.HQ_COMPLETED_VACANCIES,
          },
        ],
      },
      {
        title: "Leave requests",
        childsItem: [
          {
            title: "Approved Request",
            to: ROUTES.HQ_MANAGEMENT.HQ_APPROVED_LEAVE_REQUEST,
          },
          {
            title: "Pending Request",
            to: ROUTES.HQ_MANAGEMENT.HQ_PENDING_LEAVE,
          },
        ],
      },
      // {
      //   title: "Register leave ",
      //   to: "",
      // },
    ],
  },
  {
    title: "Statistics",
    to: ROUTES.INSTITUTE.STATICSTIS,
  },
  {
    title: "Chat",
    to: ROUTES.HQ_MANAGEMENT.CHAT,
  },
  {
    title: "More",
    childsItem: [
      {
        title: "My consultants",
        to: ROUTES.INSTITUTE.MY_CONSULTANTS,
      },
      {
        title: "FAQ",
        to: ROUTES.FAQ,
      },
      {
        title: "About DoHR",
        to: "",
      },
      {
        title: "Contact DoHR",
        to: "",
      },
    ],
  },
];

const menus = {
  teacher: Teacher,
  hq_management: Hq_Management,
  school: Scl_Management,
  Institute: Institute,
};

export default menus;
