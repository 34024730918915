import moment from "moment";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../../components";
import { PrimaryButton } from "../../../../components/Button";
import { TYPE } from "../../../../const/getType";
import { getAbsentStaff } from "../../../../redux/Common/api";
import { RootState } from "../../../../redux/store";
import { CardWraper } from "../Vacancies/PublishedVacancies/subcomponents";
import * as XLSX from "xlsx";

const HQ_AbcentStaff = (): ReactElement => {
  const dispatch = useDispatch();
  const { AbsentStaff, userInfo, lang, isLoading } = useSelector(
    (state: RootState) => ({
      AbsentStaff: state.common.getAbsentStaff,
      isLoading: state.common.isLoading,
      userInfo: state.login.userInfo,
      lang: state.Language.language.Tables.absentStaff,
    })
  );

  const [searchTerm, setsearchTerm] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setFilter] = useState(true);

  const { tableHeader, sectionTitle } = lang;

  useEffect(() => {
    if (userInfo?.type === TYPE.INSTITUTE) {
      dispatch(getAbsentStaff({ ins_id: userInfo?.data.id }));
    } else {
      dispatch(getAbsentStaff({ ins_id: userInfo?.data.ins_id }));
    }
  }, [dispatch, userInfo?.data.id, userInfo?.data.ins_id, userInfo?.type]);
  const filteredAbsentStaff = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      AbsentStaff.filter((userFilter) => {
        if (searchTerm === "") {
          return userFilter;
        } else if (
          moment(userFilter.data.from_date)
            .format("YYYY-MM-DD")
            ?.toLowerCase()
            .includes(moment(searchTerm).format("YYYY-MM-DD"))
        ) {
          return userFilter;
        }
      }),
    [AbsentStaff, searchTerm]
  );
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredAbsentStaff.map((item) => ({
        "First Name": item.data.first_name,
        "Last Name": item.data.last_name,
        "From Date": moment(item.data.from_date).format("DD-MM-YYYY"),
        "To Date": moment(item.data.to_date).format("DD-MM-YYYY"),
        Duration: `${moment
          .duration(
            moment(item.data.to_time, "HH:mm:ss").diff(
              moment(item.data.from_time, "HH:mm:ss")
            )
          )
          .hours()}:${moment
          .duration(
            moment(item.data.to_time, "HH:mm:ss").diff(
              moment(item.data.from_time, "HH:mm:ss")
            )
          )
          .minutes()} hours`,
        "Leave Type": item.data.leave_type,
        "Substitute Required":
          item.data.substitute_required === 1 ? "Yes" : "No",
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Absent Staff");
    XLSX.writeFile(workbook, "Absent_Staff.xlsx");
  };
  return (
    <PageWrapper>
      <CardWraper width={isLoading ? "75%" : "85%"}>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
          {filter && (
            <FlexWrapper
              noMargin
              noPadding
              justifyContent="space-between"
              style={{ paddingLeft: "33rem" }}
            >
              <div>
                <PrimaryButton
                  onClick={() => {
                    setsearchTerm(moment(new Date()).format("YYYY-MM-DD"));
                  }}
                >
                  Today
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => {
                    setsearchTerm(
                      moment(
                        new Date().setDate(new Date().getDate() + 1)
                      ).format("YYYY-MM-DD")
                    );
                  }}
                >
                  Tomorrow
                </PrimaryButton>
                <PrimaryButton onClick={exportToExcel}>
                  Export to Excel
                </PrimaryButton>
              </div>
              <Input
                value={searchTerm}
                inputType="date"
                onChange={(e: any) => {
                  setsearchTerm(e);
                }}
                height="auto"
                width="auto"
              />
            </FlexWrapper>
          )}
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {AbsentStaff
                  // eslint-disable-next-line array-callback-return
                  .filter((userFilter) => {
                    if (searchTerm === "") {
                      return userFilter;
                    } else if (
                      moment(userFilter.data.from_date)
                        .format("YYYY-MM-DD")
                        ?.toLowerCase()
                        .includes(moment(searchTerm).format("YYYY-MM-DD"))
                    ) {
                      return userFilter;
                    }
                  })
                  .map((item, i) => {
                    const fromTime = moment(item.data.from_time, "hh:ss:mm");
                    const endTime = moment(item.data.to_time, "hh:ss:mm");
                    const duration = moment.duration(endTime.diff(fromTime));
                    const getHours = duration.hours();
                    const minutes = duration.minutes();
                    let hh = getHours <= 10 ? `${getHours}` : getHours;
                    let mm = minutes <= 10 ? `0${minutes}` : minutes;

                    const fromdateFormate = moment(item.data.from_date).format(
                      "M/DD/YYYY"
                    );
                    const todateFormate = moment(item.data.to_date).format(
                      "M/DD/YYYY"
                    );
                    var fDt = moment(fromdateFormate, "M/DD/YYYY");
                    var tDt = moment(todateFormate, "M/DD/YYYY");
                    var dayDiff = tDt.diff(fDt, "days");

                    return (
                      <tr key={i} style={{ textAlign: "center" }}>
                        <td>{++i}</td>
                        {/* <td>{item.data?.company_name}</td> */}
                        <td>{item.data.first_name}</td>
                        <td>{item.data.last_name}</td>
                        <td>
                          {moment(item.data.from_date).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          {moment(item.data.to_date).format("DD-MM-YYYY")}
                        </td>
                        <td>{`${
                          hh === "0" && mm === "00"
                            ? `${`${dayDiff + 1} days`}`
                            : hh > "01"
                            ? `${hh}:${mm} hours `
                            : `${hh}:${mm} hour`
                        }`}</td>
                        <td>{item.data.leave_type}</td>
                        <td>
                          {item.data.substitute_required === 1 ? "Yes" : "No"}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default HQ_AbcentStaff;
