import api from "../../../services/index";
import apiEndpoints from "../../../const/apiEndpoints";
import { GetAllLeaveReqProps, getMyInstituteResponse } from "./typings";
import { createAsyncThunk } from "@reduxjs/toolkit";
import history from "../../../const/history";
import ROUTES from "../../../const/routes";
import { RootState } from "../../store";
import { TYPE } from "../../../const/getType";

export const GetAllLeaveApi = createAsyncThunk(
  "leaveMnagement/getAllLeave",
  async (requestPayload: any): Promise<Array<GetAllLeaveReqProps>> => {
    const response = await api.post(
      `${apiEndpoints.getAllLeaveReq}`,
      requestPayload
    );
    return response.data.message;
  }
);

export const ApproveLeaveApi = createAsyncThunk(
  "leaveMnagement/approveLeave",
  async (requestPayload: {
    id: string;
    approved_by: string;
    approved_by_id: string;
    approved_or_denied_on: string;
  }) => {
    const response = await api.post(apiEndpoints.approveLeave, requestPayload);
    if (response.data) {
      history.push(ROUTES.HQ_MANAGEMENT.HQ_APPROVED_LEAVE_REQUEST);
    }
    return response.data;
  }
);

export const DenyLeaveApi = createAsyncThunk(
  "leaveMnagement/DenyLeave",
  async (
    requestPayload: { id: string; email_id: string; reason: string },
    { getState, dispatch }
  ) => {
    const {
      login: { userInfo },
    } = getState() as RootState;
    const response = await api.post(apiEndpoints.DenyAbsence, requestPayload);
    if (response.status === 200) {
      if (userInfo?.type === TYPE.CUSTOMER) {
        dispatch(
          GetAllLeaveApi({
            ins_id: userInfo?.data.ins_id,
            status: "0",
          })
        );
      } else {
        dispatch(
          GetAllLeaveApi({
            ins_id: userInfo?.data.id,
            status: "0",
          })
        );
      }
    }
    return response.data.message;
  }
);

export const getMyInstitute = createAsyncThunk(
  "g/g",
  async (reauestPayload: any): Promise<Array<getMyInstituteResponse>> => {
    const response = await api.post(
      `${apiEndpoints.getMyInstitute}`,
      reauestPayload
    );
    return response?.data?.message;
  }
);
