export const TableHeaderData = [
  "SNo",
  "Name",
  "Leave Type",
  "From Date",
  "To Date",
  "Duration",
  "Comment",
  "Status",
  "Action",
];
