import styled from "styled-components";
import { Button } from "react-bootstrap";
import { colors } from "../../const/theme";

interface ButtonProps {
  backgroundcolors?: string;
}

const ActionButton = styled(Button)<ButtonProps>`
  background: ${({ Background }) =>
    Background ? Background : colors.darkBlue};
  color: ${({ color }) => (color ? color : colors.white)};
  border: ${({ border }) => (border ? border : `2px solid ${colors.darkBlue}`)};
  font-weight: 600;
  height: 35px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1vw;
  border-radius: 25px;
  padding: 0 15px;
  margin-right: 3%;
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0px 2px 5px gray;
    background: ${({ backgroundcolors }) =>
      backgroundcolors ? backgroundcolors : ""};
  }
  @media (max-width: 550px) {
    font-size: 10px;
  }
`;

export default ActionButton;
