export const TableHeaderData = [
  "SNo",
  "Date",
  "Start Time",
  "End Time",
  "Duration",
  "Subject",
  "Year Group",
  "Absent Staff Name",
];
