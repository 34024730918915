import { DropdownListProps } from "../components/EditableDropdown/typings";
import { ChooseConsultant, GetPositionResposne } from "../redux/Common/types";
import {
  getMyInstituteResponse,
  RequrInfo,
} from "../redux/Hq_Management/LeaveManagement/typings";
import {
  GetOrganizationProps,
  Role,
} from "../redux/Hq_Management/Vacancy/types";
import { GetLeaveTypes } from "../redux/Teachers/RegisterAbsence/types";

export const LeaveTypeDropdown = (
  Leavelists: Array<GetLeaveTypes>
): Array<DropdownListProps> => {
  const LeaveTypes = Leavelists.map((item: GetLeaveTypes) => ({
    id: item?.id,
    name: item?.leave_type,
  }));
  return LeaveTypes;
};

export const RoleDropdown = (
  Rolelists: Array<Role>
): Array<DropdownListProps> => {
  const Role = Rolelists.map((item: Role) => ({
    id: item?.id,
    name: item?.title,
  }));
  return Role;
};

export const OrganizationTypeDropdown = (
  OrganizationTypelists: Array<GetOrganizationProps>
): Array<DropdownListProps> => {
  const OrgType = OrganizationTypelists.map((item: GetOrganizationProps) => ({
    id: item?.id,
    name: item?.organization_type,
  }));
  return OrgType;
};

export const getRoleDropdown = (
  roleProps: Array<getMyInstituteResponse>
): Array<DropdownListProps> => {
  const [role] = roleProps?.map((data) => data?.requirement_info);
  const roleList = role.map((item: RequrInfo) => ({
    id: item?.title_id,
    name: item?.title,
  }));
  return roleList;
};

export const getConsultantsDropDown = (
  consProps: Array<ChooseConsultant>
): Array<DropdownListProps> => {
  const consList = consProps.map((data: ChooseConsultant) => ({
    id: data.id,
    name: data.publish_to,
  }));
  return consList;
};

export const getPositionDropdown = (
  positions: Array<GetPositionResposne>
): Array<DropdownListProps> => {
  const consList = positions.map((data: GetPositionResposne) => ({
    id: data.id,
    name: data.position,
  }));
  return consList;
};
