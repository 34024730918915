import { ReactElement, useEffect, useState } from "react";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import {
  ActionButton,
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { CardWraper } from "../../Vacancies/PublishedVacancies/subcomponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { GetAllLeaveApi } from "../../../../../redux/Hq_Management/LeaveManagement/api";
import { TYPE } from "../../../../../const/getType";
import moment from "moment";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";

const HQ_ApprovedLeaveRequest = (): ReactElement => {
  const dispatch = useDispatch();

  const { getAllLeaveReq, userInfo, isLoading, lang } = useSelector(
    (state: RootState) => ({
      getAllLeaveReq: state.LeaveMaster.getAllLeaveReq,
      isLoading: state.LeaveMaster.isLoading,
      userInfo: state.login.userInfo,
      lang: state.Language.language.Tables.approved,
    })
  );

  const { sectionTitle, tableHeader } = lang;

  useEffect(() => {
    if (userInfo?.type === TYPE.INSTITUTE) {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.id,
          status: "1",
        })
      );
    } else {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.ins_id,
          status: "1",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [show, setShow] = useState(false);
  const [leave, setLeave] = useState({
    Name: "",
    LeaveType: "",
    SubstituteRequired: "",
    FromDate: "",
    ToDate: "",
    Status: "",
    Submittedon: "",
    Approvedon: "",
    Approvedby: "",
    Reason: "",
    StartTime: "",
    EndTime: "",
    Duriation: "",
    Percentageofyourparentalleave: "",

    Firstinitialofthechildsname: "",

    Yearandmonthofthebirthdate: "",
    Approximateduedate: "",
  });

  return (
    <PageWrapper>
      <CardWraper>
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          // size="sm"
        >
          <ModalHeader closeButton style={{ textAlign: "center" }}>
            Leave Details
          </ModalHeader>
          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <Title style={{ textAlign: "start" }}>
                Name: {leave.Name}
                <br />
                Leave type: {leave.LeaveType}
                <br />
                SubstituteRequired:{leave.SubstituteRequired}
                <br />
                FromDate:{leave.FromDate}
                <br />
                ToDate:{leave.ToDate}
                <br />
                Status:{leave.Status}
                <br />
                Submittedon:{leave.Submittedon}
                <br />
                {leave.Status === "Cancelled" ? "Deniedon" : "Approvedon"}:
                {leave.Approvedon}
                <br />
                {leave.Status === "Cancelled" ? "Deniedby" : "Approvedby"}:
                {leave.Approvedby}
                <br />
                {leave.Status === "Cancelled"
                  ? `Denied reason:${leave.Reason}`
                  : ""}
                StartTime:{leave.StartTime}
                <br />
                EndTime:{leave.EndTime}
                <br />
                Duriation:{leave.Duriation}
                <br />
                Percentage of your parental leave:{" "}
                {leave.Percentageofyourparentalleave}
                <br />
                First initial of the childs name:
                {leave.Firstinitialofthechildsname}
                <br />
                Year and month of the birth date:{" "}
                {leave.Yearandmonthofthebirthdate}
                <br />
                Approximate due date: {leave.Approximateduedate}
                <br />
              </Title>
            </FlexWrapper>
            <FlexWrapper justifyContent="center" noPadding noMargin>
              <ActionButton
                onClick={() => {
                  setShow(false);
                }}
              >
                Close
              </ActionButton>
            </FlexWrapper>
          </Modal.Body>
        </Modal>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getAllLeaveReq.map((item, index) => (
                  <tr
                    key={index}
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      setShow(true);
                      setLeave({
                        Name: `${item.first_name} ${item.last_name}`,
                        LeaveType: item.leave_type,
                        SubstituteRequired:
                          item.substitute_required === 0 ? "No" : "Yes",
                        FromDate: moment(item.from_date).format("YYYY-MM-DD"),
                        ToDate: moment(item.to_date).format("YYYY-MM-DD"),
                        Status:
                          item.is_denied === true
                            ? "Cancelled"
                            : item.is_approved === 0
                            ? "Pending"
                            : "Approved",
                        Submittedon: moment(item.from_date).format(
                          "YYYY-MM-DD"
                        ),
                        Approvedon: moment(
                          item.approved_or_denied_on,
                          "DD-MM-YYYY"
                        ).format("YYYY-MM-DD"),
                        Approvedby: item.approved_by,
                        Reason: item.deny_reason,
                        StartTime: item.from_time,
                        EndTime: item.to_time,
                        Duriation: `${moment
                          .duration(
                            moment(item.to_time, "HH:mm:ss").diff(
                              moment(item.from_time, "HH:mm:ss")
                            )
                          )
                          .hours()}:${moment
                          .duration(
                            moment(item.to_time, "HH:mm:ss").diff(
                              moment(item.from_time, "HH:mm:ss")
                            )
                          )
                          .minutes()} hours`,
                        Percentageofyourparentalleave:
                          item.Parental_leave_percentage,

                        Firstinitialofthechildsname: item.child_first_name,

                        Yearandmonthofthebirthdate: item.date_info,
                        Approximateduedate: moment(item.appro_due_date).format(
                          "YYYY/MM"
                        ),
                      });
                    }}
                  >
                    {item?.is_approved === 1 ? (
                      <>
                        <td>{index + 1}</td>
                        <td>{`${item.first_name}${item.last_name}`}</td>
                        <td>{item.leave_type}</td>
                        <td>
                          {" "}
                          {item.substitute_required === 0 ? "No" : "Yes"}{" "}
                        </td>
                        <td>{moment(item.from_date).format("DD-MM-YYYY")}</td>
                        <td>{moment(item.to_date).format("DD-MM-YYYY")}</td>
                        <td>
                          {item.is_denied === true ? (
                            <span style={{ color: "red" }}>Cancelled</span>
                          ) : item.is_approved === 0 ? (
                            <span style={{ color: "red" }}>Pending</span>
                          ) : (
                            <span style={{ color: "green" }}>Approved</span>
                          )}
                        </td>
                        <td>{`${moment
                          .duration(
                            moment(item.to_time, "HH:mm:ss").diff(
                              moment(item.from_time, "HH:mm:ss")
                            )
                          )
                          .hours()}:${moment
                          .duration(
                            moment(item.to_time, "HH:mm:ss").diff(
                              moment(item.from_time, "HH:mm:ss")
                            )
                          )
                          .minutes()} hours`}</td>
                        <td>{moment(item.created_at).format("DD-MM-YYYY")}</td>
                        <td>{item.approved_or_denied_on}</td>
                        <td>{item.approved_by}</td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default HQ_ApprovedLeaveRequest;
