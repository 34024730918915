const apiEndpoints = {
  login: "/Login",
  getLeaveTypes: "/Get_Leave_Master",
  AddSchedule: "/Create_Schedule",
  getSchedule: "/Get_my_schedule",
  RegisterAbsence: "/Create_Absence",
  getRole: "/Get_Title",
  get_organization: "/Get_Organization ",
  createInternalVacancy: "/Create_Uncovered_vacancy_internal",
  createExternalVacancy: "/Create_Uncovered_vacancy_external",
  getAllLeaveReq: "/Get_All_Absence",
  approveLeave: "/Approve_Absence",
  getUncovered: "/Get_Uncovered_schedule",
  getPublishedVacancy: "/Get_pub_vacany",
  getCompletedVacancy: "/Get_MyCompletedVacancy",
  getMyAbsencybyTeacher: "/Get_my_absence",
  createSubstitute: "/Create_Substitute",
  getMyInstitute: "/Get_MyInstitute",
  createCustomer: "/Create_Customer",
  getMyCovered: "/Get_my_covered",
  getIcovered: "/Get_I_covered",
  getDraft: "/Get_My_Ins_Vacancy",
  getCoverdSchedule: "/Get_CoveredSchedules",
  createSeperateVacancy: "/Create_vacancy",
  getTeacher: "/Get_Internal_staff",
  getAbsentStaff: "/Get_Absent_staff",
  getMyAssignedCover: "/Get_MyCus_vacancy",
  getMyConsultants: "/Get_My_Consultant",
  addMyConsultant: "/Add_My_Consultant",
  giveFeedBack: "/Consultant_Feedback",
  getMyCreatedCustomer: "/Get_My_Customer",
  approveVacancy: "/Approve_My_Vacancy",
  getCoverCount: "/Get_Cover_Count",
  getLessonCount: "/Get_Lesson_Count",
  getAbsentCount: "/Get_Absence_Count",
  chooseConsultant: "/Get_PublidhTo",
  DenyAbsence: "/Deny_Absence",
  iCoverd: "/Get_I_covered",
  getgraphCountIns: "/Get_Absence_Count_Graph",
  getAssignedCount: "/Assigned_Cover_Count",
  getmychatList: "/Get_My_Chat",
  getmychatMessage: "/Get_Chat",
  sendChatMessage: "/Chat",
  getmyinsConsultant: "/Get_Ins_My_Consultant",
  getProfile: "/Get_profile",
  denyAssignVacn: "/Deny_My_Vacancy",
  deleteMySchedule: "/Delete_mySchedule",
  editMySchedule: "/Edit_mySchedule",
  getSingleSchedule: "/Get_single_schedule",
  deleteAbsence: "/delete_absence_request",
  getMyAbsencybyid: "/Get_Selected_Absence",
  editAbsence: "/Edit_absence_management",
  //INSTITUTE
  deleteAdministators: "/delete_customer",
  changeCustomerStatus: "/inactive_customer",
  //common
  deleteUncovered: "/delete_administators",
  deletePublishedVac: "/delete_published_vacancy",
  createcontact: "/Add_contactUs_Message",
  emailvalidation: "/Email_validation",
  openapplicationregister: "/Create_Open_Application",
  getwebsitead: "/Get_Advertisement",
  VacanciesApplication: "/Create_ad_form",
  NewsEmail: "/add_subscribe_email",
  forgotPassword: "/Forgot_Password",
  DeleteVacancy: "/delete_vacancy",
  DeleteConsultant: "/delete_consultant",
};

export default apiEndpoints;
