import React, { ReactElement, useEffect } from "react";
import { RootState } from "../../../../../redux/store";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PageWrapper,
  FlexWrapper,
  SectionTitle,
  ActionButton,
  Loader,
} from "../../../../../components";
import ROUTES from "../../../../../const/routes";
import { Table } from "react-bootstrap";
import { tableHeader } from "./const";
import { CardWraper } from "../../unCovered/subcomponent";
import { getCreatevacancyWithoutLesson } from "../../../../../redux/Hq_Management/Vacancy/api";

const UncoveredVacancyWithoutLesson = (): ReactElement => {
  const {
    Vacancy: { getWithoutLessonUncoveredList, isLoading },
    userdata,
  } = useSelector(
    (state: RootState) => ({
      Vacancy: state.Vacancy,
      userdata: state.login?.userInfo?.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getCreatevacancyWithoutLesson({
        created_by: userdata?.unique_id,
      })
    );
  }, [dispatch, userdata]);

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
          width="100%"
        >
          <SectionTitle title={"Ad hoc vacancies list"} noMargin />
          <ActionButton
            onClick={() => {
              history.push(ROUTES.CREATE_VACANCY);
            }}
          >
            Create an ad hoc vacancy
          </ActionButton>
        </FlexWrapper>

        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center" noMargin>
            <Table borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader?.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getWithoutLessonUncoveredList?.map((list, index) => {
                  return (
                    <tr key={index}>
                      <td>{list?.position}</td>
                      <td>{list?.v_date}</td>
                      <td>{list?.from_time}</td>
                      <td>{list?.to_time}</td>
                      <td>{list?.location}</td>
                      <td>
                        {(list?.publish_to_external === 1 && "External") ||
                          (list?.publish_to_internal === 1 && "Internal")}
                      </td>
                    </tr>
                  );
                })}{" "}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default UncoveredVacancyWithoutLesson;
