import {
  GetAllLeaveReqProps,
  getMyInstituteResponse,
  initialState,
} from "./typings";
import { DenyLeaveApi, GetAllLeaveApi, getMyInstitute } from "./api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const LeaveMaster = createSlice({
  name: "leaveMaster",
  initialState,
  reducers: {},
  extraReducers: {
    [GetAllLeaveApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetAllLeaveApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetAllLeaveReqProps>>
    ) => {
      state.getAllLeaveReq = action.payload;
      state.isLoading = false;
    },
    [GetAllLeaveApi.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getMyInstitute.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getMyInstituteResponse>>
    ) => {
      state.getMyidInstitute = action.payload;
    },
    [DenyLeaveApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.message = action.payload;
    },
  },
});

export default LeaveMaster.reducer;
