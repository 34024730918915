const swedish = {
  header: {
    main: {
      ourSolutions: "Våra tjänster",
      vacancies: "Lediga jobb",
      more: "Mer",
      aboutUs: "Om oss",
      contactUs: "Kontakta oss",
      news: "Nyheter",
      faq: "Vanliga frågor",
      integrity: "Integritetspolicy och användarvillkor",
      apply: "Kom igång",
      signIn: "Logga in",
      forgotPassword: "Glömt ditt lösenord?",
      para1:
        "För att återställa ditt lösenord, ange din registrerade e-postadress nedan. Om du inte ser några e-postmeddelanden från oss, kolla din skräppost.",
      emailPlaceholder: "E-postadress",
      submit: "Skicka",
      para2:
        "Tveka inte att kontakta oss om du har problem med länken eller ditt lösenord",
      checkYourEmail: "Kolla din e-post",
      para3:
        "Du kommer att få ett e-postmeddelande med en länk för att återställa ditt lösenord inom kort. Om du inte har fått e-postmeddelandet från DoHR, kolla din skräppost eller skicka in din e-postadress igen.",
      backToLogin: "Tillbaka till inloggning",
      needHelp: "Behöver du hjälp?",
      contactSupport: "Kontakta supporten",
    },
    sideHeading: {
      SA_and_SM: "Vikarie- och frånvarohantering",
      Recruitment: "Rekrytering",
      Personal_Development: "Onboarding och personalutveckling",
    },
  },
  landing: {
    apply: "Ansök nu",
    learnmore: "Läs mer",
    imagin: "Bli DoHR-kund (/ˈdɔr/) och få tillgång till...",
    sequence1: [
      "Enkel hantering av personalens frånvaro.",
      "En daglig översikt över din personals frånvaro.",
      "De bästa vikarierna utan bekymmer och krångel.",

      "De bästa kandidaterna för dina lediga tjänster utan stress och krångel.",

      "De bästa talangerna på din arbetsplats till ett kostnadseffektivt pris.",
      "Data för olika statistikbehov.",

      "Professionell utveckling för din personal på ett och samma ställe.",
    ],
    sequence2: [
      "Vill du jobba extra utan krångel?",
      "Vill du själv bestämma när och var du vill jobba extra?",
    ],
    findout: "Läs mer om hur DoHR kan hjälpa dig!",
    stopImg: "  Tja, sluta inbilla dig och vidta åtgärder!",
    getStarted: "Komma igång",
    extrawork: "Söker du extrajobb?",
    ifsounds:
      "Om detta låter som du, ansök nu för att anmäla ditt intresse för att bli en DoHR vikarie eller läs mer om hur det fungerar.",
    seeking: "Söker du nya utmaningar?",
    great: "Då har du hittat rätt!",
    readyto:
      "Vi på DoHR är engagerade i att hjälpa dig hitta ett jobb som matchar dina karriärmål. ",
    refer: `Läs mer för att ta reda på hur det fungerar!`,
    open: "Spontanansökan",
  },

  footer: {
    Our_solutions: "Våra tjänster",
    saam: "Vikarie- och frånvarohantering",
    Recruitment: "Rekrytering",
    Personal_Development: "Onboarding och personalutveckling",
    gswith: "Kom igång med DoHR",
    Customers: "För kunder",
    Substitutes: "För vikarier",
    Jobseekers: "För jobbsökande",
    more: "Mer",
    Visiting_address: "Besöksadress:",
    Bollgatan: "Bollgatan 3B, Växjö",

    contact: {
      Contact_Us: "Kontakta oss",
      Phone: "Telefon: 0470 - 78 50 00",
      Opening: "Öppettider vardagar: 09:00 - 16:00",
      closing: "Lunchstängt: 12:00 - 13:00",
    },
    other: {
      Download: "Ladda ner vår app",
      follow_us: "Följ oss",
      Privacy_statement: "Sekretess Policy",
      Cookie_Policy: "Cookie Policy",
      Integrity_and_security: "Integritet och säkerhet",
      terms: "Avtal & villkor",
      rights: "Copyright 2024 DoHR ",
    },
  },
  contactForm: {
    fill: "Skicka din förfrågan till oss, så hjäper vi dig gärna!",
    Contactusform: "Kontakta oss Form",
    CompanyName: "Företagsnamn",
    FirstName: "Förnamn",
    LastName: "Efternamn",
    EnterName: "Skriv namn",
    EnterMailid: "E-post",
    PhoneNumber: "Telefonnummer",
    EnterPhoneNumber: "Telefonnummer",
    Description: "Skriv ditt meddelande",
    descriptionPlaceHolder: "Skriv ditt meddelande",
    submit: "Skicka",
    messages: "Din förfrågan har nu skickats!",
  },
  dashboard: {
    tab: {
      header: {
        today: "I dag",
        tommorow: "Imorgon",
        custom: "Anpassat datum",
      },
      content: {
        absence: "Total frånvaro",
        filled: "Fyllda Lediga platser",
        unfilled: "Ouppfyllda Lediga platser",
        covered: "Totalt täckt",
        unCovered: "Totalt avslöjat",
      },
      teacherContent: {
        myAssignedCover: "Mitt tilldelade omslag",
      },
    },
    balance: {
      hoursBalance: "Timmar Saldo",
      hoursPurchased: "Totalt antal inköpta timmar",
      usedHours: "Totalt antal använda timmar",
      remainingHours: "Totalt antal minnestimmar",
      topUp: "Fyll på",
    },
  },
  signUp: {
    our_Solutions_1: " Lägg ut dina behov till DoHR!",
    our_Solutions_2: "Läs mer och kom igång...",
    LearnMore: "Läs mer",
    absent_management: "Vikarie- och frånvarohantering (SAAM)",
    recruitment: "Rekrytering",
    personal_growth: "Personlig utveckling",
    saam: {
      have: "DoHR hjälper dig att hitta vikarier snabbt och enkelt när du använder vår mobilapp eller webbaserade applikation!",
      learnSaam: "Läs mer om DoHR webbaserad applikation",
      getMobileApp: "Kom igång med DoHR mobilapp",
      whether:
        "Oavsett om du väljer att använda DoHR mobilapp eller DoHR webbaserad applikation, kommer du att...",
      cover: "Hantera personalfrånvaro snabbt och effektivt",
      rightpeople: "Spara din tid ",
      avoid: "Få vikarier i sista minuten",
      stop: "Hantera tidrapporter utan krångel",
      daily: "Få en daglig översikt över din personals frånvaro till hands ",
      get: "Få statistik och uppföljning över personalens frånvaro",
      pop: "Endast tillgänglig i den webbaserade applikationen",
      which: "Vilken av våra lösningar passar dig bäst?",
      doMobile: "DoHR mobilapp",
      doWeb: "DoHR webbaserad applikation",
      table: {
        publish: "Publicering av jobb",
        overjobs: "Översikt av publicerade jobb",
        time: "Godkännande av tidrapporter",
        chat: "Chatt",
        management: "Hantering av ledighetsansökningar",
        register: "Registrering av arbetsschema",
        overview: "Personalfrånvaroöversikt",
        effective: "Effektiv resurshantering",
        discount: "Rabatterat timpris",
        statics: "Statistik",
      },
      popModal:
        "DoHR webbaserad applikation för vikarie- och frånvarohantering ger dig ett mer effektivt sätt att hantera personalens frånvaro samt tillgång till olika typer av statistik. Det ger dig flexibiliteten att lägga ut dina vikariebehov till DoHR eller helt enkelt använda plattformen med dina egna resurser. För mer information kontakta oss.",
    },
    getstartpage: {
      header_1: "Oavsett om du är kund eller arbetssökande,",
      header_2: "uppnå dina mål med DoHR!",
      subheading_1: "DoHR för kunder",
      subheading_2: "DoHR för vikarier",
      subheading_3: "DoHR för jobbsökande",
      button: "Kom igång",
    },
    getstarted_substitute: {
      header: "Sluta leta, börja jobba!",
      headerpara_1:
        " DoHR söker dig som vill jobba extra som vikarie för att hjälpa våra kunder",
      headerpara_2:
        " att hantera korttidsfrånvaro av personal med kort varsel.",
      headerpara_3:
        " För att anmäla ditt intresse för att bli en DoHR vikarie,",
      headerpara_4: " ladda ner DoHR mobilapp eller ansök nu.",
      applynow: "Ansök nu",
      subheading: "Hur blir man en DoHR vikarie?",
      subheading_1: "Ladda ner DoHR mobilappen",
      subheadingpara_1_1: "Hämta DoHR appen från",
      subheadingpara_1_2: "Google Play eller App Store",
      subheading_2: "Fyll i ansökan",
      subheadingpara_2_1: " på DoHR hemsidan",
      subheadingpara_2_2: "eller använd länken ",
      subheadingpara_2_3: "på DoHR mobilappen",
      subheading_3: "Rekrytering och introduktion",
      subheadingpara_3_1: "Genomför introduktionsutbildning",
      subheadingpara_3_2: "för vikarier efter godkänd",
      subheadingpara_3_3: "rekryteringsprocess",
      subheading_4: "Logga in",
      subheadingpara_4_1: "Logga in med de",
      subheadingpara_4_2: "inloggningsuppgifter ",
      subheadingpara_4_3: "du har fått",
      subheading_5: "Acceptera jobb",
      subheadingpara_5_1: "Acceptera och slutför jobb",
      subheading_6: "Rapportera timmar",
      subheadingpara_6_1: "Rapportera dina arbetade ",
      subheadingpara_6_2: "timmar och få betalt ",
      lastline_1: "Se",
      lastline_2: "vanliga frågor ",
      lastline_3: "för ytterligare information",
    },
    getstarted_jobseekers: {
      JobSeekerHeader1: "DoHR följer gärna med dig",
      JobSeekerHeader2: "på din karriärresa!",
      JobSeekerPara:
        "Vi letar alltid efter nya talanger och är redo att hjälpa dig att matcha dina karriärmål med våra kunders behov.",
      JobseekerPara1_1:
        "Låt oss upptäcka tillsammans hur vi kan hjälpa dig att hitta ditt drömjobb!",
      JobSeekerPara1:
        "Se våra aktuella lediga jobb eller fyll i spontanansökan",
      JobSeekerPara2: "så att vi kan hitta ett jobb som passar just dig!",
      Curentopenings: "Lediga jobb",
      Openapplications: "Spontanansökan",
      work: "Hur går det till?",
      govacancy: "Gå till Lediga jobb ",
      browse: " Kolla igenom de lediga jobben och ansök",
      application: "Spontanansökan",
      fill: " Om du inte hittar ett jobb",
      fill1: "som passar dig, fyll i ",
      fill2: "en spontanansökan",
      next: "Vad kommer sen?",
      review: " Vi kommer att granska ",
      review1: "din ansökan och kontaktar dig ",
      review2: "inom 2-3 veckor",
      openapplication: "Spontanansökan",
    },

    customer: {
      stop: "Få vikarier utan stress och krångel!",
      straight: "Kom igång direkt!",
      download: "Ladda ner DoHR mobilapp och anlita våra vikarier på en gång!",
      there: "Men det finns mer...",
      how: "Hur fungerar DoHR mobilapp?",
      app: "Ladda ner DoHR appen",
      complete: "Skapa konto",
      log: "Logga in",
      publish: "Publicera jobb",
      approve: "Godkänn tidrapport",
    },
    recruitments: {
      make: "Få kostnadseffektiv och framgångsrik rekrytering med hjälp av DoHR!",
      heading1: "Ordet",
      heading2: "rekrytering",
      heading3: " förknippas vanligtvis med",
      heading4: "höga kostnader.",
      heading5: " Många företag har svårt att motivera",
      heading6: "de höga kostnaderna för att använda ett",
      heading7: "rekryteringsföretag",
      heading8: "för att hitta personal.",
      heading9: "Denna process är tidskrävande och kostsam",
      heading10: " för företaget.",
      heading11: " När du lägger ut dina personalbehov till ",
      heading12: "DoHR",
      heading13: "hjälper vi dig att hitta rätt kandidater mot en",
      heading14: " fast avgift.",
      strategy: "Varför är rekrytering med DoHR kostnadseffektiv?",
      tofind: "Kontakta oss för mer information!",
      tooltip:
        "En fast avgift per tjänst gäller för alla tjänster i alla sektorer. För utbildningssektorn erbjuder vi ett obegränsat antal lärartjänster per skola under hela året mot en fast avgift. För ytterligare information, kontakta oss.",
      means: "Rekrytering med DoHR innebär...",
      stress: "Stressfri rekrytering",
      outsource:
        "Outsourcing av annonsering, kandidat-screening och granskning",
      turn: "Kompletta rekryteringstjänster",
      best: "Framgångsrik rekrytering av kompetenta kandidater",
      cost: "Engångskostnad utan dolda avgifter",
    },
    Onboarding_development: {
      c: "K",
      o: "o",
      m: "m",
      i: "m",
      n: "e",
      g: "r",
      s: "s",
      o1: "n",
      o2: "a",
      n2: "rt",
    },
    vacancies: {
      open: "Dina nya möjligheter med DoHR!",
      talent: "Vi är alltid på jakt efter nya talanger!",
      see: "Se våra lediga jobb nedan eller skicka in en spontanansökan så att vi kan hitta ett jobb som passar just dig!",
      openapplication: "Spontanansökan",
      current: "Lediga jobb",
      apply: "Ansök",
      location: "Ort",
      sector: "Sektor",
      search: "Sök på jobbtitel",
      close: "Stäng",
      readmore: "Läs mer",
    },
    about: {
      our: "Vår berättelse är enkel, egentligen!",
    },
    news: {
      what: "Nytt hos DoHR!",
      newsinsight: "Nyheter",
      filter: "Filtrera efter kategori eller månad",
      cat: "Kategori",
      emailmessage: "Vi har tagit emot din prenumeration!",
      close: "Stäng",
      categorylist: [
        {
          id: "01",
          name: "För kunder",
        },
        {
          id: "02",
          name: "För vikarier",
        },
        {
          id: "03",
          name: "För andra arbetssökande",
        },
        {
          id: "04",
          name: "Alla",
        },
      ],
      forcus: "För kunder",
      forsub: "För vikarier",
      forother: "För andra arbetssökande",
      all: "Alla",
      search: "Sök",
      share: "Dela",
      learn: "Läs ",
      more: "mer",
      stay: "Håll dig uppdaterad med våra nyheter!",
      email: "E-post",
      submit: "Skicka",
    },
    faq: {
      title: "Vanliga frågor",
      substitutes: "Vikarier",
      subques1: "Vad innebär det att vara en vikarie?",
      subans1:
        "Som vikarie kommer du tillfälligt att ersätta en ordinarie anställd under dennes frånvaro. Du kommer att arbeta per timme och har flexibiliteten att arbeta extra när det passar dig.",
      subques2:
        "Vad är ett utdrag ur belastningsregistret och varför behöver jag ett?",
      subans2:
        "Enligt svensk lag ska alla som arbetar inom skola och förskola ha ett gilltigt utdrag ur belastningsregistret. För mer information och för att beställa en kopia, besök",
      subques3: "Hur blir jag en DoHR vikarie?",
      subans3_1: "Vänligen fyll i denna ",
      subans3_2: " ansökan",
      subans3_3:
        ' eller ansök under "Söker du extrajobb?" här på vår hemsida. Vi kommer att kontakta de kandidater vi är intresserade av att gå vidare med rekryteringsprocessen.',
      subques_4:
        "Var är det mest troligt att jag kommer att arbeta som DoHR vikarie?",
      subans_4:
        "De sektorer där vi sannolikt kommer att ha lediga jobb är baserade på våra kunders behov. För närvarande kommer våra kunder från olika sektorer, inklusive utbildning, omsorg, kontor och administration med mera.",
      subques5: "Hur länge varar typiska jobb för DoHR vikarie?",
      subans5:
        "Kundens behov avgör hur länge ett typiskt jobb varar, vilket kan variera från några timmar till flera dagar.",
      subques6: "Hur får jag ett jobb när jag är en DoHR vikarie?",
      subans6:
        "Som DoHR-vikarie får du tillgång till vår DoHR mobilapp för att kunna se alla lediga jobb och acceptera ett jobb som passar dig.",
      subques7: "Kan jag skicka mitt CV?",
      subans7_1: "Vi accepterar endast",
      subans7_2: "online ansökningar",
      subans7_3: " via hemsidan.",
      customer: "Kunder",
      cusques1: "  Vad är DoHR?",
      cusans1:
        "På DoHR (/ˈdɔr/) - Dimensions of HR, arbetar vi med att hjälpa våra kunder att hitta de bästa möjliga och mest prisvärda bemanningslösningarna, oavsett om behoven är permanenta, tillfälliga eller sista minuten. Vi erbjuder mobilapp- och webbaserade lösningar för att hjälpa våra kunder att effektivt hantera olika aspekter av HR.",
      cusques2: "Varför ska vi lägga ut våra rekryteringsbehov till DoHR?",
      cusans2:
        "När du lägger ut dina personalbehov till DoHR hjälper vi dig att hitta rätt kandidater mot en fast avgift.",
      cusques3: "Vad gör jag om jag behöver en vikarie?",
      cusans3:
        " Ladda ner vår mobilapp och få tillgång till våra vikarier så fort behovet uppstår.",
      cusques4: "Hur får jag åtkomst till DoHR webbaserade applikation?",
      cusans4_1:
        "  Vi är glada att du är intresserad av att använda vår webbaserade applikation!",
      cusans4_2: "Kontakta oss",
      cusans4_3: "så hjälper vi dig att komma igång direkt.",
      jobseekers: "Jobbsökande",
      jobques1: "  Vilka är DoHR kunder?",
      jobans1:
        "DoHR kunder kommer från olika branscher, exempelvis utbildning, omsorg, IT, kontorsarbete och administration.",
      jobques2: "Vad är en spontanansökan?",
      jobans2:
        " Vi tror på vikten av professionellt nätverkande, och vi inbjuder dig att fylla i vår spontanansökan så att vi kan hitta ett jobb som passar just dig.",
      jobques3: "Kan jag skicka mitt CV?",
      jobans3_1: "  Vi accepterar endast",
      jobans3_2: "  ansökningar",
      jobans3_3: "via hemsidan",
      jobques4:
        "Kan jag fortfarande skicka in min ansökan om jag bor utanför Sverige?",
      jobans4:
        "Vi samarbetar med kunder som välkomnar internationella proffs så vi uppmuntrar dig att gå igenom våra aktuella öppningar eller skicka in en öppen ansökan.",
    },
  },
  Tables: {
    teacher: {
      mySchedule: {
        sectionTitle: "Mitt schema",
        tableHeader: [
          "Dag",
          "Starttid",
          "Sluttid",
          "Ämne",
          "Årsgrupp",
          "Plats/Rum",
          "Handling",
        ],
      },
    },
    absentStaff: {
      sectionTitle: "Frånvarande personal",
      tableHeader: [
        "Nej",
        // "Organisationens namn",
        "Förnamn",
        "Efternamn",
        "Datum från",
        "Datum till",
        "Varaktighet",
        "Typ av ledighet",
        "Ersättare krävs",
      ],
    },
    management: {
      sectionTitle: "Användar administration",
      tableHeader: [
        "Nej",
        "För namn",
        "Efter namn",
        "E-post adress",
        "Roll",
        "Status",
        "Handling",
      ],
      button: "Skapa a ny Användare",
      modal: "Är du säker på att du vill ta bort?",
    },
    uncovered: {
      sectionTitle: "Otäckt",
      tableHeader: [
        "Datum",
        "Frånvarande personalnamn",
        "Årsgrupp",
        "Ämne",
        "Rum/Plats",
        "Starttid",
        "Sluttid",
        "Lesson Plan Instruction",
        "Kicka",
        "Handling",
      ],
      button: "Skapa lediga tjänster",
    },
    cover: {
      sectionTitle: "Täckt",
      tableHeader: [
        "Datum",
        "Starttid",
        "Sluttid",
        "Årsgrupp",
        "Rum/Plats",
        "Frånvarande personalnamn",
        "Ersättande namn",
        "Täckt",
      ],
    },
    draft: {
      sectionTitle: "Mitt utkast",
      tableHeader: [
        "SNej",
        "Placera",
        "Dag",
        "Ledig tjänst",
        "Starttid",
        "Sluttid",
        "Handling",
      ],
    },
    published: {
      sectionTitle: "Publicerat Vakanser",
      tableHeader: [
        "S.No",
        "Position",
        "Vacancy date",
        "Start Time",
        "End Time",
        "Published date-time",
        "Published",
        "Status",
        "Detail view",

        // "Name of the Organisation",
        "Action",
      ],
    },
    completed: {
      sectionTitle: "Avslutade vakanser",
      tableHeader: [
        "Nej",
        "Fullgjord av",
        "Ledig tjänst",
        "Starttid",
        "Sluttid",
        "Placera",
        "Original",
        "Rapporterad",
        "Godkänd",
        "Status",
        "Detaljerad vy",
        "Handling",
      ],
      dropList: [
        {
          id: "01",
          name: "Lärare",
        },
        {
          id: "02",
          name: "Konsult",
        },
      ],
      placeholder: {
        dropdown: "Välj en dropdown",
      },
    },
    approved: {
      sectionTitle: "Godkänd ledighetsförfrågan",
      tableHeader: [
        "S.Nr",
        "namn",
        "Lämna typ",
        "Ersättare krävs",
        "Från datum",
        "Hittills",
        "Status",
        "Varaktighet",
        "Inlämnad den",
        "Godkänd den",
        "Godkänd av",
      ],
      button: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    pending: {
      sectionTitle: "Väntande ledighetsbegäran",
      tableHeader: [
        "S.Nr",
        "namn",
        "Lämna typ",
        "Ersättare krävs",
        "Från datum",
        "Hittills",
        "Från tid",
        "Till tid",
      ],
      tableHeaderSh: [
        "S.Nr",
        "namn",
        "Lämna typ",
        "Ersättare krävs",
        "Från datum",
        "Hittills",
        "Från tid",
        "Till tid",
        "Handling",
      ],
      button: {
        approve: "Godkänna",
        deny: "Förneka",
      },
    },
    myconsultant: {
      sectionTitle: "Mina konsulter",
      tableHeader: ["S.Nr", "namn", "E-post", "Arbetsinstitution"],
    },
    statistic: {
      sectionTitle: " Statistisk",
      selectDate: "Välj Datum",
    },
    chats: {
      sectionTitle: "Chatt",
      tableHeader: [
        "S.Nej",
        "Namn",
        "Position",
        "Vacancy Date",
        "Start Time",
        "End Time",
        "Achtin",
      ],
    },
  },
  register: {
    sectionTitle: "Vikarieansökan",
    sectionprocedure:
      "Tack för ditt intresse för att bli en del av DoHR vikarie team. Fyll i ansökan så kontaktar vi dig efter att vi har granskat den.",
    personal: "Mina personuppgifter",
    work: "Min arbetslivserfarenhet",
    availablity: "Min tillgänglighet",
    lang: "Mina språkkunskaper",
    other: "Övrig information",
    firstName: "Förnamn",
    lastName: "Efternamn",
    address: "Adress",
    email: "E-post",
    dob: "Födelsedatum",
    city: "Stad",
    area_name: "Ort",
    Post_code: "Postnummer",
    phoneNumber: "Kontaktnummer ",
    swedishNumber: "Svenskt person nummer ",
    studyTime: "Ange timmar per vecka",
    selfEmploye: "Jag är egen företagare/frilansare",
    swedishBankAccount: "Jag har ett svenskt bankkonto",
    student: "Jag är student",
    education_type: "Välj studietakt",
    Time: [
      {
        id: "01",
        name: "Heltid",
      },
      {
        id: "02",
        name: "Deltid",
      },
    ],
    Uploadproof: "Ladda upp ditt studiebevis  ",
    Uploadproofi:
      "Studiebeviset kan vara antingen ett antagningsbevis, ett CSN-kort eller något liknande",
    prefferedWorkInsti: "Jag vill jobba inom",
    preferedWorkLoc: "Mitt föredragna arbetsområde",
    preferedWorkLoclabel: "Ange stad (till exempel Stockholm)",
    doing: "Berätta vad du sysslar med",
    description: "Beskriv kort varför du skulle vilja arbeta som vikarie",
    workExperienceinSchool:
      "Jag har erfarenhet av att arbeta med barn/unga vuxna inom skolan",
    workExperienceoutSchool:
      "Jag har erfarenhet av att arbeta med barn/unga vuxna utanför skolan",
    otherOccupation: "Jag har annan arbetslivserfarenhet",
    workStatrt: "När kan du börja?",
    Assoonaspossible: "Så snart som möjligt",
    Specifydate: "Ange datum",
    Icanstart: "Jag kan börja",
    date: "Ange datum",
    swedish: "Svenska",
    english: "Engelska",
    otherLange: "Annat språk",
    fluent: "Flytande",
    intermediate: "Mellanliggande",
    begginer: "Nybörjare",
    policeCLearenceReport: "Jag har ett giltigt utdrag ur belastningsregister",
    drivingLicence: "Jag har ett giltigt körkort",
    nameOfEmploye: "Arbetsgivare",
    durations: "Varaktighet",
    titleofRole: "Titel",
    keyRes: "Arbetsbeskrivning",
    companyOfName: "Ange företagsnamn",
    nameofIns: "Ange skolans namn",
    freelancer: "Jag är pensionär",
    primary_occupation:
      "Jag har en annan huvudsaklig sysselsättning på minst 50%",
    selectDp: "Välj",
    upfile: "Ladda up filen",
    howMayHours: "Välj timmar/vecka",
    workWeekend: "Jag kan jobba kvällar/helger",
    enterName: "Skriv namn",
    declaration_1:
      " DoHR ansvarar för att registrera de personuppgifter du lämnar i din ansökan. För mer information, se vår",
    declaration_2: " Integritetspolicy och användarvillkor.",
    declaration_3:
      " Genom att lämna in  mina  personuppgifter och ansökan till DoHR, bekräftar jag att:",
    checkbox_1:
      " Jag har läst och godkänner Integritetspolicy och användarvillkor",
    checkbox_2:
      "Jag har lämnat fullständig och korrekt information i min ansökan, och jag förstår att om jag lämnar felaktig information kan min ansökan avvisas eller avslutas",
    checkbox_3:
      "Jag samtycker till att DoHR kan spara och bearbeta den information som lämnas och därefter samlas in, dela information om nya uppdrag och använda information för jobbrelaterade marknadsföringsändamål",
    submit: "Skicka",
    errormsgcheck:
      "Alla kryssrutor måste markeras innan du skickar in din ansökan.",
    mail: "Det finns redan en ansökan med den här e-postadressen. Vänligen använd en annan e-postadress eller om du har frågor, skicka ett e-postmeddelande till support@dohr.io",
    onsubmit: "Tack för att du skickade din ansökan till DoHR!",
    message: "Din ansökan har skickats in!",
    mailidexist:
      "Ett konto med den här e-postadressen finns redan. Försök att logga in eller välj glömt lösenord.",
    YESORNO: [
      {
        id: "01",
        name: "Ja",
      },
      {
        id: "02",
        name: "Nej",
      },
    ],
    HOURSELECTION: [
      {
        id: "01",
        name: "30+ timmar per vecka ",
      },
      {
        id: "02",
        name: "  15-30 timmar per vecka ",
      },
      {
        id: "03",
        name: " 5-15 timmar per vecka ",
      },
      {
        id: "04",
        name: "Flexibel för alla ovanstående alternativ ",
      },
    ],
    Preschool: "Förskola",
    school: "Grundskola",
    Gymnasium: "Gymnasium",
    CareInstitution: "Omsorg (äldreboende/hemtjänst)",
    OtherSpecify: "Om annat, ange",
    DOB: "åååå/mm/dd",
    LANG: "swe",
    DATES: "åå/mm",
    Specify: "Beskriv",
    Beginner: "Grundläggande kunskaper",
    Intermediate: "Goda kunskaper",
    Proficient: "Mycket goda kunskaper",
    Fluent: "Flytande",
    Native: "Modersmål",
    Otherlanguage: "Annat språk",
    submits: "Spara",
    cancel: "Stäng",
    workSweden: "Jag har tillstånd att arbeta i Sverige",
    swedishlaw1:
      "Enligt svensk lag ska alla som arbetar inom skola och förskola ha ett gilltigt utdrag ur belastningsregistret. För mer information och för att beställa en kopia, besök",
    swedishlaw2:
      "Kom ihåg att maila oss en kopia på ditt utdrag ur belastningsregistret när du har fått det, då du inte kan börja jobba utan det.",
    swedishlaw11:
      " Enligt svensk lag ska alla som arbetar inom skola och förskola ha ett gilltigt utdrag ur belastningsregistret. För mer information och för att beställa en kopia, besök https://polisen.se/tjanster-tillstand/belastningsregistret/skola-eller-forskola/",
    permitlaw:
      " För att arbeta i Sverige behöver du ett giltigt arbetstillstånd.",
    permitlaw2: "För mer information, besök ",
    Passport: "Ladda upp fotosidan av ditt pass",
    policecert: " Vad är ett utdrag ur belastningsregistret?",
    PermitCard:
      "Ladda upp en kopia av ditt uppehålls- och arbetstillståndskort",
    hear: " Hur fick du veta om DoHR?",
    EUcitizen: "Jag är EU-medborgare",
    nonEUcitizen: "Jag är inte EU-medborgare",
    howKnowAboutUs: [
      {
        id: "01",
        name: "Arbetsförmedlingen",
      },
      {
        id: "02",
        name: "Marknadsföringsevenemang",
      },
      {
        id: "03",
        name: "Blev tipsad",
      },
      {
        id: "04",
        name: "Jobbannons",
      },
      {
        id: "05",
        name: "Instagram",
      },
      {
        id: "06",
        name: "Facebook",
      },
      {
        id: "07",
        name: "Linkedin",
      },
      {
        id: "08",
        name: "Google",
      },
      {
        id: "09",
        name: "Tiktok",
      },
    ],
  },
  applicationForm: {
    title: "Spontanansökan",
    Jobapplication: "Jobbansökan",
    titledetails:
      "Tack för att du tar dig tid att fylla i vår spontanansökan. När du skickar in din ansökan får du ett bekräftelsemejl från oss.",
    titledetailsvacancy:
      "Tack för att du tar dig tid att fylla i jobbansökan. När du skickar in din ansökan får du ett bekräftelsemejl från oss.",
    personaldetails: "Mina personuppgifter",
    firstName: "Förnamn",
    lastName: "Efternamn",
    address: "Adress",
    email: "E-post",
    dob: "Födelsedatum",
    city: "Stad",
    area_name: "Ort",
    select: "Välj",
    worklocation: "Mitt föredragna arbetsområde",
    statelocation: "Ange arbetsområde",
    namecity: "  Ange stad (till exempel Stockholm)",
    Post_code: "Postnummer",
    telusbriefly: " Beskriv kort varför du är intresserad av denna tjänst",
    phoneNumber: "Kontaktnummer ",
    swedishNumber: "Swedish Personal number ",
    swedishBankAccount: "Jag har ett svenskt bankkontot",
    workexperience: "Min arbetslivserfarenhet",
    Employmenthistory: "Arbetslivserfarenhet",
    Employer: "Arbetsgivare",
    Occuppation: "Ange namn",
    DATES: "åå/mm",
    From: "Från",
    to: "Till",
    Relevanteducation: "Min utbildning",
    Institutionname: "Ange namn på skolan",
    Education: "Beskrivning av utbildning",
    occupitationalSector: "Välj yrkesområde",
    stateyourOccupation: "Välj yrke",
    jobTitle: "Välj jobbtitel",
    preferredWorkIns: "Ange din föredragna arbetsplats",
    preSchool: "Förskola",
    school: "Grundskola",
    highSchool: "Gymnasieskola",
    preferredLocation: "State your preferred location",
    specifyNameCity: "Specify Name of the City",
    areQualifiedTeacher: "Är du en behörig lärare?",
    qualifiedSubject: "Jag är behörig att undervisa i följande ämne/ämnen",
    qualifiedAgeGroups: "Jag är behörig att undervisa följande åldersgrupper",
    swedishlärarlegitimation: "Jag har en svensk lärarlegitimation",
    uploadLärarlegitimation: " Ladda upp en kopia på lärarlegitimation",
    TeachingExperience: "Vilka ämnen har du undervisningserfarenhet av?",
    ageGroupsExperience: "Vilka åldersgrupper har du erfarenhet av?",
    currentlyQWualifiedTeacher: "Jag ska vara en behörig lärare",
    studyingteacher: "Studerar du för närvarande till lärare?",
    willQualifiedSubjects:
      "Jag kommer att vara behörig att undervisa i följande ämne(n)",
    willQualifiedAgeGroups:
      "Jag kommer att vara behörig att undervisa följande åldersgrupper",
    langSkills: "Mina språkkunskaper",
    nameOflanf: "Name of the Language",
    workSweden: "Jag har tillstånd att arbeta i Sverige",
    howDoHR: "How did you find out about DoHR? ",
    areEveningsweekends: "Kan du jobba kvällar/helger?",
    policeCLearenceReport: "Jag har ett giltigt utdrag ur belastningsregistret",
    DOB: "åååå/mm/dd",

    education: "Education",
    completedIn: "Completed in",
    EUcitizen: "Jag är EU-medborgare",
    nonEUcitizen: "Jag är inte EU-medborgare",
    Passport: "Ladda upp fotosidan av ditt pass",
    PermitCard:
      "Ladda upp en kopia av ditt uppehålls- och arbetstillståndskort",
    linkedin: "Ange länken till din LinkedIn ",
    hear: " Hur fick du veta om DoHR?",
    nonqualified: "Jag är en obehörig lärare med undervisningserfarenhet",
    toteacher: "Jag studerar just nu till lärare",
    submit: "Skicka",
    howKnowAboutUs: [
      {
        id: "01",
        name: "Arbetsförmedlingen",
      },
      {
        id: "02",
        name: "Marknadsföringsevenemang",
      },
      {
        id: "03",
        name: "Blev tipsad",
      },
      {
        id: "04",
        name: "Jobbannons",
      },
      {
        id: "05",
        name: "Instagram",
      },
      {
        id: "06",
        name: "Facebook",
      },
      {
        id: "07",
        name: "Linkedin",
      },
      {
        id: "08",
        name: "Google",
      },
      {
        id: "09",
        name: "Tiktok",
      },
    ],
    avalibalityList: [
      {
        id: "01",
        name: "När som helst",
      },
      {
        id: "01",
        name: "Ange datum",
      },
    ],
    OccupitationalList: [
      {
        id: "01",
        name: "Utbildning",
      },

      {
        id: "02",
        name: "Omsorg",
      },
      {
        id: "03",
        name: "Administration",
      },
      {
        id: "04",
        name: "IT",
      },
      {
        id: "05",
        name: "Övrigt",
      },
    ],
    position: [
      {
        id: "01",
        name: "Lärare",
      },
      {
        id: "02",
        name: "Icke lärare",
      },
    ],
    teaching: [
      {
        id: "01",
        name: "Förskolelärare",
      },
      {
        id: "02",
        name: "Grundskolelärare",
      },
      {
        id: "03",
        name: "Gymnasielärare",
      },
      {
        id: "04",
        name: "Speciallärare/specialpedagog",
      },
    ],

    non_teaching: [
      {
        id: "01",
        name: "Studieyrkesvägledare",
      },
      {
        id: "02",
        name: "Fritidsledare",
      },
      {
        id: "03",
        name: "Skolsköterska",
      },
      {
        id: "04",
        name: "Skolkurator",
      },
      {
        id: "5",
        name: "Bibliotekarie",
      },
      {
        id: "6",
        name: "Administration",
      },
      {
        id: "7",
        name: "Övrig elevstöd",
      },
      {
        id: "8",
        name: "Ledning",
      },
      {
        id: "9",
        name: "IT",
      },
      {
        id: "10",
        name: "Övrigt",
      },
    ],
    subjectList: [
      {
        id: "01",
        name: "Engelska",
      },
      {
        id: "02",
        name: "Matematik",
      },
      {
        id: "03",
        name: "NO",
      },
      {
        id: "04",
        name: "SO",
      },
      {
        id: "05",
        name: "Svenska",
      },
      {
        id: "06",
        name: "Idrott",
      },
      {
        id: "07",
        name: "Moderna språk",
      },
      {
        id: "08",
        name: "Bild",
      },
      {
        id: "09",
        name: "Hemkunskap",
      },
      {
        id: "10",
        name: "Slöjd",
      },
      {
        id: "11",
        name: "Träslöjd ",
      },
      {
        id: "12",
        name: "Musik",
      },
      {
        id: "13",
        name: "Livskunskap",
      },
      {
        id: "14",
        name: " IT",
      },
      {
        id: "15",
        name: "Klasslärare",
      },
    ],
    ageGroupList: [
      {
        id: "01",
        name: "Förskola",
      },
      {
        id: "02",
        name: "Åk F-3",
      },
      {
        id: "03",
        name: "Åk 4-6",
      },
      {
        id: "04",
        name: "Åk 7-9",
      },
      {
        id: "05",
        name: "Gymnasium",
      },
    ],
  },
};

export default swedish;
