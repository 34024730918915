import { ReactElement, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
  ToastMessage,
} from "../../../../components";
import { PrimaryButton } from "../../../../components/Button";
import { DropdownListProps } from "../../../../components/EditableDropdown/typings";
import { RoleDropdown } from "../../../../helpers/dropdown";
import { hasFormError } from "../../../../helpers/formValidation";
import { getMyInstitute } from "../../../../redux/Hq_Management/LeaveManagement/api";
import { GetRole } from "../../../../redux/Hq_Management/Vacancy/api";
import { updateCustomerRej } from "../../../../redux/login/action";
import { createCustomer } from "../../../../redux/login/api";
import { RootState } from "../../../../redux/store";
import validateCustomerForm from "./helper";
import { CardWraper } from "./subcomponents";
import { customerField } from "./types";
import { Modal } from "react-bootstrap";
import { Label } from "../../../../typography";
import { CancleButton, DeleteButton } from "../ViewCustomer/subcomponents";

const CreateCustomer = ({ count }: any): ReactElement => {
  const dispatch = useDispatch();

  const {
    createCustomerRes,
    getMyidInstitute,
    userInfo,
    login: { hascustomerRejected },
    isLoading,
    Vacancy: { role },
  } = useSelector(
    (state: RootState) => ({
      createCustomerRes: state.login.createCustomerRes,
      isLoading: state.login.isLoading,
      userInfo: state.login.userInfo,
      getMyidInstitute: state.LeaveMaster.getMyidInstitute,
      login: state.login,
      Vacancy: state.Vacancy,
    }),
    shallowEqual
  );

  const [values, setValues] = useState(createCustomerRes);
  const [errors, setErrors] = useState({} as Record<string, string>);

  const roleDropDown = role ? RoleDropdown(role) : [];

  const validateForm = (field: customerField) => {
    setErrors(
      validateCustomerForm({
        values,
        errors,
        field,
      })
    );
  };

  useEffect(() => {
    dispatch(GetRole());
    dispatch(
      getMyInstitute({
        id: userInfo?.data?.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [instituteDetails] = getMyidInstitute?.map((data) => data);
  const [createUser, setCreateUser] = useState<any>();
  return (
    <PageWrapper>
      <Modal
        show={createUser ? true : false}
        size={"sm"}
        centered={true}
        backdrop="static"
        keyboard={false}
      >
        <FlexWrapper justifyContent="center" width="100%">
          <FlexWrapper justifyContent="center">
            <Label>
              Please confirm if you want to add this user. Selecting 'Yes' will
              share the username and password with the registered work email
              address.
            </Label>
          </FlexWrapper>
          <FlexWrapper noPadding>
            <CancleButton
              onClick={() => {
                setCreateUser("");
              }}
            >
              No
            </CancleButton>
            <DeleteButton
              onClick={() => {
                setCreateUser("");

                dispatch(
                  createCustomer({
                    ...values,
                    company_name: instituteDetails?.institute_name,
                    organization_no: instituteDetails?.institute_id,
                    organization_type: instituteDetails?.institute_type,
                    organization_type_id: instituteDetails?.institute_type_id,
                    ins_id: userInfo?.data.id,
                  })
                );
              }}
            >
              Yes
            </DeleteButton>
          </FlexWrapper>
        </FlexWrapper>
      </Modal>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title="Add user" noMargin hasBackButton />
        </FlexWrapper>
        <FlexWrapper>
          <DropdownWrapper>
            <EditableDropdown
              isRequired
              title="Permission type "
              dropdownList={roleDropDown}
              onBlur={() => validateForm("title")}
              error={errors.title}
              placeholder={"Select Permission type"}
              handleSelect={(value: DropdownListProps) => {
                setValues({
                  ...values,
                  title: `${value?.name}`,
                  title_id: `${value?.id}`,
                });
                setErrors(
                  validateCustomerForm({
                    values: { ...values, title: value?.name },
                    errors,
                    field: "title",
                  })
                );
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              label="Additional information"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  instructions: value,
                });
              }}
              placeholder="Additional information"
              value={values?.instructions}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              isRequired
              label="First Name"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  first_name: value,
                });
              }}
              placeholder="First Name"
              onBlur={() => {
                validateForm("first_name");
              }}
              error={errors.first_name}
              value={values?.first_name}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              isRequired
              label="Last Name"
              onBlur={() => {
                validateForm("last_name");
              }}
              error={errors.last_name}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  last_name: value,
                });
              }}
              placeholder="Last Name"
              value={values?.last_name}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              isRequired
              label="Email"
              onBlur={() => {
                validateForm("email_id");
              }}
              error={errors.email_id}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  email_id: value,
                });
              }}
              placeholder="Enter E-Mail"
              value={values?.email_id}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              isRequired
              label="Password"
              onBlur={() => {
                validateForm("password");
              }}
              error={errors.password}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  password: value,
                });
              }}
              placeholder="Enter Password"
              value={values?.password}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              isRequired
              label="Phone Number"
              onBlur={() => {
                validateForm("telephone_number");
              }}
              error={errors.telephone_number}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  telephone_number: value,
                });
              }}
              placeholder="Enter Number"
              value={values?.telephone_number}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              isRequired
              label="Address"
              onBlur={() => {
                validateForm("address");
              }}
              error={errors.address}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  address: value,
                });
              }}
              placeholder="Enter Address"
              value={values?.address}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              isRequired
              label="Postal code"
              onBlur={() => {
                validateForm("postal_code");
              }}
              error={errors.postal_code}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  postal_code: value,
                });
              }}
              placeholder="Enter Postal Code"
              value={values?.postal_code}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              isRequired
              label=" Area name"
              onBlur={() => {
                validateForm("area_name");
              }}
              error={errors.area_name}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  area_name: value,
                });
              }}
              placeholder="Enter area name"
              value={values?.area_name}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              label=" Invoice Address"
              onBlur={() => {
                validateForm("invoice_address");
              }}
              error={errors.invoice_address}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  invoice_address: value,
                });
              }}
              placeholder="Enter Invoice Address"
              value={values?.invoice_address}
            />
          </DropdownWrapper>

          <DropdownWrapper>
            <Input
              label="Invoice Area Name"
              onBlur={() => {
                validateForm("invoice_area_name");
              }}
              error={errors.invoice_area_name}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  invoice_area_name: value,
                });
              }}
              placeholder="Enter area name"
              value={values?.invoice_area_name}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              label="Invoice MailId"
              onBlur={() => {
                validateForm("invoice_email_id");
              }}
              error={errors.invoice_email_id}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  invoice_email_id: value,
                });
              }}
              placeholder="Enter invoice mail id"
              value={values?.invoice_email_id}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              label="Invoice postal code"
              onBlur={() => {
                validateForm("invoice_postal_code");
              }}
              error={errors.invoice_postal_code}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  invoice_postal_code: value,
                });
              }}
              placeholder="Enter Invoice Postal Code"
              value={values?.invoice_postal_code}
            />
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper justifyContent="center">
          {isLoading ? (
            <Loader />
          ) : (
            <PrimaryButton
              onClick={() => {
                const validationError = validateCustomerForm({
                  values,
                  errors,
                });
                if (hasFormError(validationError)) {
                  setErrors(validationError);
                } else {
                  setCreateUser(true);
                }
              }}
              marginTop="32px"
            >
              Add
            </PrimaryButton>
          )}
        </FlexWrapper>
      </CardWraper>
      {/* {hascustomerCreated && (
        <ToastMessage
          show={!!hascustomerCreated}
          bg="success"
          onCloseHandler={() => {
            dispatch(updateCustomerCre(false));
          }}
          message="Successfully Customer Created"
        ></ToastMessage>
      )} */}
      {hascustomerRejected && (
        <ToastMessage
          show={!!hascustomerRejected}
          bg="primary"
          top="90px"
          onCloseHandler={() => {
            dispatch(updateCustomerRej(false));
          }}
          message="There is already a user with this email address. Please, try a different email address to add a new user."
        ></ToastMessage>
      )}
    </PageWrapper>
  );
};

export default CreateCustomer;
