import { ReactElement, useEffect } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import theme from "./const/theme";
import { Route, Router, Switch } from "react-router-dom";
import styled from "styled-components";
import history from "./const/history";
import Routes from "./routes";
import BREAKPOINTS from "./const/breakpoint";
import useBreakpoint from "use-breakpoint";
import Header from "./components/Header";
import UserHeader from "./container/Login/Header";
import "./App.css";
import LandingPage from "./container/Login";
import ROUTES from "./const/routes";
import SignInOptions from "./container/Login/ChooseSignIn";
import Register from "./container/Login/ChooseSignIn/register/register";
import RegisterForm from "./container/Login/ChooseSignIn/register/registerForm";
import { Development } from "./container/Login/ourSolution/development";
import StaffAbsence from "./container/Login/ourSolution/staffAbsence";
import Recruitment from "./container/Login/ourSolution/Recruitment";
import ContactForm from "./container/Login/ChooseSignIn/register/contact";
import About from "./container/Login/About";
import { weight } from "./const/fonts";
import {
  Employment,
  PrivacyPolicy,
  Reserve,
  StaticSiteFAQ,
  TermsConditions,
} from "./pages";
import OurSolution from "./container/Login/ourSolution";
import Customer from "./container/GetStart/Customer";
import Substitute from "./container/GetStart/Substitute";
import JobSeeker from "./container/GetStart/JobSeeker";
import Vacancies from "./container/Vacancies";
import News from "./container/More/News";
import { CookiesConsent } from "./components";
import JobSeekerForm from "./pages/ApplicationForm";
import VacanciesApplicationForm from "./container/Vacancies/ApplicationForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { uodateUserInfo } from "./redux/login/action";

const Body = styled.div`
  display: flex;
  min-height: calc(95vh - 40px);
`;

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: "Montserrat", sans-serif;
    scroll-behavior: smooth;
    overflow: auto;
  }
  td { 
   
    text-align: center;   
    vertical-align:middle;
    padding: 0.5rem 0.5rem;
    font-size: 0.90rem;
    font-weight: ${weight.xnormal};
  }
  th { 
    color: whitesmoke;
    text-align: center;
    padding: 0.6rem 0.6rem;
  } 
  thead{
    
    font-size: 0.95rem;
  }

  @media (max-width:600px){
    td { 
    font-size:10px;
  }
  thead{
    font-size: 9px;
  }
  }

  @media (min-width: 600px) {
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-height: auto;
    font-family: "Montserrat", sans-serif !important;
  }
}
`;

const App = (): ReactElement => {
  const { userInfo, isLoggedIn } = useSelector(
    (state: RootState) => ({
      userInfo: state.login.userInfo,
      isLoggedIn: state.login.isLoggedIn,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [breakpoint]);

  useEffect(() => {
    if (userInfo === null) {
      const localMessage: any = localStorage.getItem("message");
      const userDetails = JSON.parse(localMessage);
      dispatch(uodateUserInfo(userDetails));
    }
  }, [dispatch, userInfo]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router history={history}>
        {!isLoggedIn && (
          <>
            <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />{" "}
            <Route path={ROUTES.TERMS_CONDITIONS} component={TermsConditions} />{" "}
            <Route path={ROUTES.EMPLOYEMENT} component={Employment} />
            <Route path={ROUTES.RESERVE} component={Reserve} />
          </>
        )}
        {isLoggedIn ? <Header /> : <UserHeader />}
        <Body>
          {isLoggedIn ? (
            <Routes />
          ) : (
            <>
              <CookiesConsent />
              <Switch>
                <Route
                  exact
                  path={ROUTES.LANDING_PAGE}
                  component={LandingPage}
                />
                <Route
                  exact
                  path={ROUTES.STATIC_FAQ}
                  component={StaticSiteFAQ}
                />
                <Route path={ROUTES.SIGN_IN_OPTION} component={SignInOptions} />
                <Route path={ROUTES.REGISTER_IN_OPTION} component={Register} />
                <Route path={ROUTES.REGISTER_FORM} component={RegisterForm} />
                <Route path={ROUTES.DEVELOPMENT} component={Development} />
                <Route path={ROUTES.STAFF_ABSENCE} component={StaffAbsence} />
                <Route path={ROUTES.RECRUITMENT} component={Recruitment} />
                <Route path={ROUTES.CONTACT_FORM} component={ContactForm} />
                <Route path={ROUTES.ABOUT} component={About} />
                <Route path={ROUTES.OUR_SOLUTION} component={OurSolution} />
                <Route path={ROUTES.CUSTOMER} component={Customer} />
                <Route path={ROUTES.SUBSTITUTE} component={Substitute} />
                <Route path={ROUTES.JOB_SEEKER} component={JobSeeker} />
                <Route path={ROUTES.VACANCIES} component={Vacancies} />
                <Route path={ROUTES.NEWS} component={News} />
                <Route
                  path={ROUTES.JOB_SEEKER_FORM}
                  component={JobSeekerForm}
                />
                <Route
                  path={ROUTES.VACANCIES_APPLICATION_FORM}
                  component={VacanciesApplicationForm}
                />
              </Switch>
            </>
          )}
        </Body>
      </Router>
    </ThemeProvider>
  );
};

export default App;
