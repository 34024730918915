import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { Card, Form } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../../const/theme";

export const CardWraper = styled(Card)`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 1rem auto;
`;

export const TabWrapper = styled.div`
  font-family: "Montserrat", sans-serif;
  .nav-tabs {
    border-bottom: 1px solid ${colors.lightGrey};
  }

  .nav-item {
    button {
      color: ${colors.gray};
    }
  }
  .nav-link.active {
    color: ${colors.white};
    background-color: #2097f5;
    border-left: 1px solid ${({ theme }) => theme.tab.border};
    border-top: 1px solid ${({ theme }) => theme.tab.border};
    border-right: 1px solid ${({ theme }) => theme.tab.border};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const TableWrapper = styled.div`
  border: 1px solid lightgray;
`;

export const FormWrapper = styled.div`
  margin-left: 4%;
`;

export const AddressLable = styled.p`
  margin-bottom: 1px;
`;

export const AutoCompleteWrapper = styled.div`
  display: block;
  width: 22%;
  margin-top: 8px;
  margin-left: 2%;
`;

export const FormCheck = styled(Form.Check)`
  margin-left: 10px;
`;

export const CheckboxWrapper = styled.span`
  display: flex;
  margin: 0 1px 10px;
  color: ${colors.darkBlue};
`;
export const CheckBoxText = styled.span`
  margin-right: 35px;
  position: relative;
  left: 10px;
`;
export const VacancyDropDownWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Info_Icon = styled(FontAwesomeIcon)``;

export const IconButton = styled.button`
  background: none;
  border: none;
  width: 40px;
  color: white;
  &:hover,
  &:active,
  &:focus {
    background: none;
    border: none;
  }
`;

export const Tooltiped = styled(Tooltip)`
  color: ${colors.darkBlue};
`;
