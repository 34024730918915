import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  consultantId,
  CoveredProps,
  Data,
  completedVacancyprops,
  getDraftandPublishVacancy,
  GetOrganizationProps,
  GetTeacher,
  getUncoveredResponse,
  initialState,
  Role,
  TeacherId,
  GetUncoveredWithoutLessonResponse,
} from "./types";
import {
  CreateInternalVacancyApi,
  GetCompletedVacancyApi,
  GetOrganization,
  GetRole,
  getUnCovered,
  GetMyDraft,
  getTeacher,
  getCoveredVacancy,
  CreateExternalVacancyApi,
  giveFeedBack,
  getCreatevacancyWithoutLesson,
} from "./api";

export const vacancySlice = createSlice({
  name: "hqManagement",
  initialState,
  reducers: {
    updateSelectedUncoveredDetails: (
      state,
      action: PayloadAction<Data | null>
    ) => {
      state.updateUncovered = action.payload;
    },
    updateAssignInternal: (state, action: PayloadAction<any>) => {
      state.setAssignInternal = action.payload;
    },
    updateAssignExternal: (state, action: PayloadAction<any>) => {
      state.setAssignExternal = action.payload;
    },
    updateVacancyDetails: (state, action: PayloadAction<any>) => {
      state.setVacancyStatus = action.payload;
    },
    resetValues: (state) => {
      state.updateUncovered = initialState.updateUncovered;
    },
    updatehasCreateVacancySuccess: (state, action: PayloadAction<boolean>) => {
      state.hasCreateVacancySuccess = action?.payload;
    },
    updateSelectedTeacherId: (state, action: PayloadAction<TeacherId>) => {
      state.setSelectedTeacherId = action.payload;
    },
    updateSelectedVacancyDetails: (
      state,
      action: PayloadAction<Array<any>>
    ) => {
      state.selectedUpdateTeacherExternal = action?.payload;
    },
    updateSelectedcosultId: (state, action: PayloadAction<consultantId>) => {
      state.setSelectedConsultantId = action.payload;
    },
    updateCreateVacancyErr: (state, action: PayloadAction<boolean>) => {
      state.createVacancyErr = action.payload;
    },
    updateCreateExternalVanc: (state, action: PayloadAction<any>) => {
      state.selectedDraft = action.payload;
    },
    updateCreateExternalVancError: (state, action: PayloadAction<any>) => {
      state.hasExternalError = action.payload;
    },
  },
  extraReducers: {
    [CreateInternalVacancyApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [CreateInternalVacancyApi.fulfilled.toString()]: (state) => {
      state.isLoading = false;
      state.hasCreateVacancySuccess = true;
      state.createVacancyErr = false;
      window.scrollTo({ top: 0 });
    },
    [CreateInternalVacancyApi.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.createVacancyErr = true;
    },
    [GetRole.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetRole.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Role>>
    ) => {
      state.isLoading = false;
      state.role = action?.payload;
    },
    [GetRole.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetOrganization.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetOrganization.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetOrganizationProps>>
    ) => {
      state.isLoading = false;
      state.GetOrganization = action.payload;
    },
    [GetOrganization.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getUnCovered.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getUnCovered.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getUncoveredResponse>>
    ) => {
      state.isLoading = false;
      state.getUncoveredData = action?.payload;
    },
    [getUnCovered.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetCompletedVacancyApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetCompletedVacancyApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<completedVacancyprops>
    ) => {
      state.isLoading = false;
      state.getCompletedVacancy = action.payload;
    },
    [GetCompletedVacancyApi.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetMyDraft.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetMyDraft.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getDraftandPublishVacancy>>
    ) => {
      state.isLoading = false;
      state.getMyDraft = action.payload;
    },
    [GetMyDraft.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getTeacher.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getTeacher.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetTeacher>>
    ) => {
      state.isLoading = false;
      state.getTeacherDetails = action.payload;
    },
    [getTeacher.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getCoveredVacancy.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getCoveredVacancy.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<CoveredProps>>
    ) => {
      state.isLoading = false;
      state.getCovered = action.payload;
    },
    [getCoveredVacancy.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [CreateExternalVacancyApi.pending.toString()]: (state) => {
      state.isLoading = true;
      state.hasExternalError = false;
    },
    [CreateExternalVacancyApi.fulfilled.toString()]: (state) => {
      state.isLoading = false;
      state.hasExternalError = false;
    },
    [CreateExternalVacancyApi.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.hasExternalError = true;
      window.scroll({ top: 0 });
    },
    [giveFeedBack.pending.toString()]: (state) => {
      state.isFeedBackGiven = false;
      state.isLoading = true;
    },
    [giveFeedBack.fulfilled.toString()]: (state) => {
      state.isFeedBackGiven = true;
      state.isLoading = false;
    },
    [giveFeedBack.rejected.toString()]: (state) => {
      state.isFeedBackGiven = false;
      state.isLoading = false;
    },

    [getCreatevacancyWithoutLesson.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getCreatevacancyWithoutLesson.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetUncoveredWithoutLessonResponse>>
    ) => {
      state.isLoading = false;
      state.getWithoutLessonUncoveredList = action?.payload;
    },
    [getCreatevacancyWithoutLesson.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
  },
});

export default vacancySlice.reducer;
