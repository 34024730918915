import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";

export const CardWraper = styled(Card)`
  display: flex;
  justify-content: center;
  width: ${({ width }) => (width ? width : "75%")};
  margin: 1rem auto;
  margin-bottom: 4%;
`;

export const SliderIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
`;
